import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createOAuth2TokenRequest,
  deleteDataSourceHeaderRequest,
  deleteDataSourceParamRequest,
  deleteOAuth2TokenRequest,
  getOAuth2TokenRequest,
  getOAuth2TokensRequest,
  updateOAuth2TokenRequest,
} from "redux/dataSources/action";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import usePrevious from "utility/hooks/usePrevious";
import { ToastOptions } from "components/toastify";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { MainContext } from "context/contexts";
import { generateCloudInstanceName } from "utility/utility";
import Parameter, { getNewParam } from "./Parameter";
import Header, { getNewHeader } from "./Header";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";
import { ReactComponent as CirclePlusIcon } from "assets/icons/circle-plus.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "assets/icons/templateIcons/edit_small.svg";
import ServiceParameterTable from "./ServiceParameterTable";
import Token from "../modals/Token";
import CustomSelect from "../CustomSelect";
import { use } from "react-dom-factories";

const DataSourceContent = ({ data, isNew = false, dama_service_id = null }) => {
  const { setIsLoading, setIsEdited } = useContext(MainContext);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    isDeletedDataSourceParamSuccess,
    isDeletedDataSourceHeaderSuccess,
    isGetOAuth2TokensSuccess,
    isGetOAuth2TokenSuccess,
    isCreatedOAuth2TokenSuccess,
    isUpdatedOAuth2TokenSuccess,
    isDeletedOAuth2TokenSuccess,
    oauth2Tokens,
    oauth2Token,
  } = useSelector((state) => state.dataSources);

  const { dataServiceDetails, isDataMarketGetServiceDetailsSuccess } =
    useSelector((state) => state.dataMarket);

  const prevIsDeletedDataSourceParamSuccess = usePrevious(
    isDeletedDataSourceParamSuccess
  );
  const prevIsDeletedDataSourceHeaderSuccess = usePrevious(
    isDeletedDataSourceHeaderSuccess
  );

  const [method, setMethod] = useState("get");
  const [authentication, setAuthentication] = useState("0");
  const [oauth2Authentication, setOauth2Authentication] = useState(false);
  const [selectedOauth2, setSelectedOauth2] = useState({
    type: null,
    value: null,
    label: null,
  });
  const [oauth2TokensData, setOauth2TokensData] = useState([]);
  const [isAddTokenModalOpen, setIsAddTokenModalOpen] = useState(false);

  const [dataClone, setDataClone] = useState({});
  const [params, setParams] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [selectedParamId, setSelectedParamId] = useState({});
  const [selectedHeaderId, setSelectedHeaderId] = useState({});
  const [isDeleteTokenModalOpen, setIsDeleteTokenModalOpen] = useState(false);
  const [isParameterDeleteModalOpen, setIsParameterDeleteModalOpen] =
    useState(false);
  const [isHeaderDeleteModalOpen, setIsHeaderDeleteModalOpen] = useState(false);
  const [modalOauth2Token, setModalOauth2Token] = useState({});

  const prevIsGetOAuth2TokensSuccess = usePrevious(isGetOAuth2TokensSuccess);
  const prevIsGetOAuth2TokenSuccess = usePrevious(isGetOAuth2TokenSuccess);
  const prevIsCreatedOAuth2TokenSuccess = usePrevious(
    isCreatedOAuth2TokenSuccess
  );
  const prevIsUpdatedOAuth2TokenSuccess = usePrevious(
    isUpdatedOAuth2TokenSuccess
  );
  const prevIsDeletedOAuth2TokenSuccess = usePrevious(
    isDeletedOAuth2TokenSuccess
  );

  useEffect(() => {
    dispatch(getOAuth2TokensRequest());

    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      setOauth2Authentication(!!data?.external_data_oauth2_id);
    }
  }, [data]);

  useEffect(() => {
    if (isGetOAuth2TokenSuccess && prevIsGetOAuth2TokenSuccess === false) {
      setModalOauth2Token(oauth2Token);
      setIsAddTokenModalOpen(true);
    }
  }, [isGetOAuth2TokenSuccess]);

  useEffect(() => {
    if (
      (isUpdatedOAuth2TokenSuccess &&
        prevIsUpdatedOAuth2TokenSuccess === false) ||
      (isCreatedOAuth2TokenSuccess && prevIsCreatedOAuth2TokenSuccess === false)
    ) {
      dispatch(getOAuth2TokensRequest());
      setIsAddTokenModalOpen(false);
      setIsDeleteTokenModalOpen(false);
    }
    if (
      isUpdatedOAuth2TokenSuccess &&
      prevIsUpdatedOAuth2TokenSuccess === false
    ) {
      toast.success(t("token_updated"), ToastOptions);
    } else if (
      isCreatedOAuth2TokenSuccess &&
      prevIsCreatedOAuth2TokenSuccess === false
    ) {
      toast.success(t("token_created"), ToastOptions);
    }
  }, [
    isUpdatedOAuth2TokenSuccess,
    prevIsUpdatedOAuth2TokenSuccess,
    isCreatedOAuth2TokenSuccess,
    prevIsCreatedOAuth2TokenSuccess,
  ]);

  useEffect(() => {
    if (
      isDeletedOAuth2TokenSuccess &&
      prevIsDeletedOAuth2TokenSuccess === false
    ) {
      const clone = structuredClone(oauth2Tokens);
      setOauth2TokensData(
        clone.map((data) => {
          return {
            label: data.name,
            value: data.external_data_oauth2_id,
          };
        })
      );
      setIsAddTokenModalOpen(false);
      setIsDeleteTokenModalOpen(false);
      toast.success(t("token_deleted"), ToastOptions);
    }
  }, [isDeletedOAuth2TokenSuccess, prevIsDeletedOAuth2TokenSuccess]);

  useEffect(() => {
    if (
      (isGetOAuth2TokensSuccess && prevIsGetOAuth2TokensSuccess === false) ||
      oauth2Tokens.length > 0
    ) {
      const clone = structuredClone(oauth2Tokens);
      setOauth2TokensData(
        clone.map((data) => {
          return {
            label: data.name,
            value: data.external_data_oauth2_id,
          };
        })
      );
    }
  }, [isGetOAuth2TokensSuccess]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && !isNew) {
      setAuthentication(data.http_auth);
      setDataClone(structuredClone(data));
      setMethod(data.method ? data.method : "");
      setParams(
        data.params ? [...data.params, getNewParam()] : [getNewParam()]
      );
      setHeaders(
        data.headers ? [...data.headers, getNewHeader()] : [getNewHeader()]
      );
    }
  }, [data]);

  useEffect(() => {
    if (isNew) {
      setParams([getNewParam()]);
      setHeaders([getNewHeader()]);
    }
  }, [isNew]);

  useEffect(() => {
    if (
      isDeletedDataSourceParamSuccess &&
      prevIsDeletedDataSourceParamSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(`${t("parameter")} ${t("deleted")}.`, ToastOptions);
    }
  }, [isDeletedDataSourceParamSuccess]);

  useEffect(() => {
    if (
      isDeletedDataSourceHeaderSuccess &&
      prevIsDeletedDataSourceHeaderSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(`${t("header")} ${t("deleted")}.`, ToastOptions);
    }
  }, [isDeletedDataSourceHeaderSuccess]);

  const deleteParam = (paramId) => {
    const paramsClone = structuredClone(params);
    const filteredParams = paramsClone.filter(
      (param) => param.external_data_param_id !== paramId
    );
    setParams(filteredParams);
    if (typeof paramId === "number") {
      setIsLoading(true);
      dispatch(deleteDataSourceParamRequest({ id, paramId }));
    } else {
      setIsEdited(true);
    }
  };

  const deleteHeader = (headerId) => {
    setIsEdited(true);
    const headersClone = structuredClone(headers);
    const filteredHeaders = headersClone.filter(
      (header) => header.external_data_header_id !== headerId
    );
    setHeaders(filteredHeaders);
    if (typeof headerId === "number") {
      setIsLoading(true);
      dispatch(deleteDataSourceHeaderRequest({ id, headerId }));
    }
  };

  const handleShowParamDeleteModal = (selectedData) => {
    setSelectedParamId(selectedData);
    setIsParameterDeleteModalOpen(true);
  };

  const handleClose = () => {
    setSelectedParamId({});
    setIsParameterDeleteModalOpen(false);
    setIsDeleteTokenModalOpen(false);
  };

  const handleConfirm = () => {
    setIsParameterDeleteModalOpen(false);
    deleteParam(selectedParamId);
  };

  const handleConfirmDeleteToken = () => {
    dispatch(deleteOAuth2TokenRequest(selectedOauth2.value));
  };

  const handleShowHeaderDeleteModal = (selectedData) => {
    setSelectedHeaderId(selectedData);
    setIsHeaderDeleteModalOpen(true);
  };

  const handleHeaderDeleteModalClose = () => {
    setSelectedHeaderId({});
    setIsHeaderDeleteModalOpen(false);
  };

  const handleHeaderDeleteConfirm = () => {
    setIsHeaderDeleteModalOpen(false);
    deleteHeader(selectedHeaderId);
  };

  const handleChangeMethod = (e) => {
    setIsEdited(true);
    setMethod(e.value);
  };

  const methods = [
    {
      value: "get",
      label: "GET",
    },
    {
      value: "post",
      label: "POST",
    },
    {
      value: "put",
      label: "PUT",
    },
  ];

  const getSelectValue = () => {
    return methods.find((data) => data.value == method);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isSelected || state.isFocused ? "#0094E5" : "#DEE2E6",
    }),
    option: (provided, state) => ({
      ...provided,
      height: 37,
      minHeight: 37,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#DEE2E6",
      borderBottom: "2px solid #f8f9fa",
      color: "#6C757D",
      paddingLeft: "10px !important",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "100%",
      color: "#6C757D",
      marginLeft: "10px",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? "#0094E5" : "#6C757D", // Custom colour
      marginRight: "10px",
    }),
  };

  const TableColumns = ({ tfoot = false, isHeaders = false }) => {
    let columns = (
      <tr className="border-0">
        <th>{t("parameter_name")}</th>
        <th>{t("value")}</th>
        <th className="text-center">
          {!tfoot && (
            <span data-tip={true} data-for="add-decision-flow-step">
              {t("flow")}
            </span>
          )}

          <ReactTooltip
            type="dark"
            place="top"
            effect="solid"
            id="add-decision-flow-step"
          >
            {t("add_to_decision_flow_step")}
          </ReactTooltip>
        </th>
        <th className="function-col" />
      </tr>
    );
    if (isHeaders) {
      columns = (
        <tr className="border-0">
          <th>{t("header")}</th>
          <th className="function-col" />
        </tr>
      );
    }
    return columns;
  };

  TableColumns.propTypes = {
    tfoot: PropTypes.bool,
    isHeaders: PropTypes.bool,
  };

  const handleToggle = (id, label, type = "edit") => {
    if (type === "edit") {
      dispatch(getOAuth2TokenRequest(id));
      setIsEdited(true);
    } else {
      setIsDeleteTokenModalOpen(true);
    }

    setSelectedOauth2({
      type,
      value: id,
      label,
    });
  };

  const handleSubmit = (target, tokenId) => {
    setIsEdited(false);
    const data = new URLSearchParams(new FormData(target));
    const url = target.token_url.value;
    const grantType = target.grant_type.value;

    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return toast.error(t("http_invalid"), ToastOptions);
    }
    for (const [key, value] of data) {
      data.set(key, value);
      if (key.includes("use_basic_auth")) {
        if (value === "true" || value === "1") {
          data.set(key, 1);
        } else {
          data.set(key, 0);
        }
      }
    }

    if (tokenId) {
      dispatch(
        updateOAuth2TokenRequest({
          data,
          id: tokenId,
        })
      );
    } else {
      dispatch(
        createOAuth2TokenRequest({
          data,
        })
      );
    }
  };

  const setToken = () => {
    setIsAddTokenModalOpen(false);
    setSelectedOauth2({ ...selectedOauth2, type: "" });
    setModalOauth2Token({});
  };

  return (
    <>
      <input type="hidden" name="is_show_token" value={oauth2Authentication} />
      <div className="row">
        <div className="col-md col-12">
          <label>{t("table_title")}</label>
          <input
            type="text"
            className="form-control border-radius-4"
            name="title"
            defaultValue={dataClone.title ? dataClone.title : ""}
            required
          />
        </div>
        <div className="col-md col-12">
          <label>{t("identifier")}</label>
          <input
            type="text"
            className="form-control border-radius-4"
            name="name"
            defaultValue={
              dataClone.name
                ? dataClone.name
                : !id
                ? generateCloudInstanceName()
                : ""
            }
            pattern="[a-zA-Z0-9_\-]+"
            title={t("only_letters_numbers_underscore_hyphen")}
            required
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9_-]/g, "");
            }}
          />
        </div>
      </div>
      {!dama_service_id && (
        <>
          <div className="align-items-center row my-md-5">
            <div className="col-lg-2 col-md-3 pb-3 pb-md-0 pr-md-0">
              <label>{t("method")}</label>
              <br />
              <CustomSelect
                defaultValue={getSelectValue}
                options={methods}
                selectKey={method}
                handleChange={handleChangeMethod}
                components={{
                  IndicatorSeparator: () => null,
                }}
                selectStyle={{
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    marginRight: "0",
                  }),
                  control: (styles) => ({
                    ...styles,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }),
                }}
              />
              <input type="hidden" name="method" value={method} />
            </div>
            <div className="col-12 col-md pl-md-0">
              <label htmlFor="endpoint_url">{t("url_endpoint")}</label>
              <input
                type="text"
                className="form-control border-radius-right-4 border-left-0"
                name="endpoint_url"
                placeholder="https://"
                defaultValue={
                  dataClone.endpoint_url ? dataClone.endpoint_url : ""
                }
              />
            </div>
          </div>
          <div className="justify-content-between row mt-5 mb-5">
            <div className="col-12 col-md-2 pb-3 pb-md-0">
              <label>{t("response_type")}</label>
              <br />
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  className={`btn outline border-radius-left-4 py-2 ${
                    (dataClone.response_type &&
                      dataClone.response_type === "json") ||
                    isNew
                      ? "active"
                      : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="response_type"
                    defaultValue="json"
                    defaultChecked={
                      (dataClone.response_type &&
                        dataClone.response_type === "json") ||
                      isNew
                        ? 1
                        : 0
                    }
                    key={dataClone.response_type}
                  />
                  JSON
                </label>
                <label
                  className={`btn outline border-radius-right-4 py-2 ${
                    dataClone.response_type && dataClone.response_type === "xml"
                      ? "active"
                      : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="response_type"
                    defaultValue="xml"
                    defaultChecked={
                      dataClone.response_type &&
                      dataClone.response_type === "xml"
                        ? 1
                        : 0
                    }
                    key={dataClone.response_type}
                  />
                  XML
                </label>
              </div>
            </div>
            <div className="col-md-2 col-12 pb-md-0 pb-3">
              {method === "post" && (
                <div>
                  <label>{t("post_data_type")}</label>
                  <br />
                  <div
                    className="btn-group btn-group-toggle border-radius-left-4"
                    data-toggle="buttons"
                  >
                    <label
                      className={`btn outline border-radius-left-4 py-2 ${
                        (dataClone.post_json && dataClone.post_json === "0") ||
                        !Object.keys(dataClone).length
                          ? "active"
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="post_json"
                        defaultValue="0"
                        defaultChecked={
                          (dataClone.post_json &&
                            dataClone.post_json === "0") ||
                          !Object.keys(dataClone).length
                            ? 1
                            : 0
                        }
                      />
                      Plain
                    </label>
                    <label
                      className={`btn outline border-radius-right-4 py-2 ${
                        dataClone.post_json && dataClone.post_json === "1"
                          ? "active"
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="post_json"
                        defaultValue="1"
                        defaultChecked={
                          dataClone.post_json && dataClone.post_json === "1"
                            ? 1
                            : 0
                        }
                      />
                      JSON
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-md-4">
              <label>{t("timeout_sec")}</label>
              <input
                type="number"
                className="form-control border-radius-4"
                name="timeout"
                defaultValue={dataClone.timeout ? dataClone.timeout : ""}
                key={dataClone.timeout}
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md col-12 pb-md-0 pb-3">
              <label>{t("http_authentication")}</label>
              <br />
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  className={`btn outline border-radius-left-4 py-2 ${
                    parseInt(authentication) ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="http_authentication"
                    value="1"
                    checked={parseInt(authentication)}
                    onChange={(e) => setAuthentication(e.target.value)}
                    key={parseInt(authentication)}
                  />
                  {t("yes")}
                </label>
                <label
                  className={`btn outline border-radius-right-4 py-2 ${
                    !parseInt(authentication) ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="http_authentication"
                    value="0"
                    checked={!parseInt(authentication)}
                    onChange={(e) => setAuthentication(e.target.value)}
                    key={parseInt(authentication)}
                  />
                  {t("no")}
                </label>
              </div>
            </div>
            {parseInt(authentication) ? (
              <>
                <div className="col-md col-12 pb-md-0 pb-3">
                  <label>{t("authentication_username")}</label>
                  <input
                    type="text"
                    className="form-control border-radius-4"
                    name="auth_username"
                    defaultValue={
                      dataClone.auth_username ? dataClone.auth_username : ""
                    }
                  />
                </div>
                <div className="col-md col-12 pb-md-0 pb-3">
                  <label>{t("http_password")}</label>
                  <input
                    type="text"
                    className="form-control border-radius-4"
                    name="auth_password"
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="row mb-5">
            <div className="col-md col-12 pb-md-0 pb-3">
              <label>{t("oauth2_authentication")}</label>
              <br />
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  className={`btn outline border-radius-left-4 py-2 ${
                    oauth2Authentication ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="use_basic_auth"
                    value="1"
                    className="form-control border-radius-4"
                    checked={oauth2Authentication}
                    onChange={(e) =>
                      setOauth2Authentication(JSON.parse(e.target.value))
                    }
                    key={oauth2Authentication}
                  />
                  {t("yes")}
                </label>
                <label
                  className={`btn outline border-radius-right-4 py-2 ${
                    !oauth2Authentication ? "active" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name="use_basic_auth"
                    value="0"
                    checked={!oauth2Authentication}
                    onChange={(e) =>
                      setOauth2Authentication(JSON.parse(e.target.value))
                    }
                    key={oauth2Authentication}
                  />
                  {t("no")}
                </label>
              </div>
            </div>
            {oauth2Authentication ? (
              <>
                <div
                  className="col-md col-12 pb-md-0 pb-3 d-flex justify-content-end"
                  style={{ paddingTop: "35px" }}
                >
                  <br />
                  <ButtonGroup className="w-100 h-fit-content">
                    <CustomSelect
                      defaultValue={
                        data &&
                        oauth2TokensData?.find(
                          (token) =>
                            token.value === data.external_data_oauth2_id
                        )
                      }
                      key={
                        data &&
                        oauth2TokensData?.find(
                          (token) =>
                            token.value === data.external_data_oauth2_id
                        )?.label
                      }
                      classes="z-index-100"
                      options={oauth2TokensData}
                      name="external_data_oauth2_id"
                      isSearchable={false}
                      isDataSourceSection={true}
                      components={{
                        Option: ({ innerProps, label, value }) => {
                          return (
                            <div
                              {...innerProps}
                              className="d-flex justify-content-between align-items-center"
                              style={{ padding: "8px", cursor: "pointer" }}
                            >
                              <p className="m-0">{label}</p>
                              <div>
                                <button
                                  title={t("edit_token")}
                                  className="border-radius-4 border-0 btn btn-outline-light mr-3"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleToggle(value, label, "edit");
                                  }}
                                >
                                  <EditIcon />
                                </button>
                                <button
                                  title={t("token_delete")}
                                  className="border-radius-4 border-0 btn btn-outline-light mr-3 option-delete"
                                  type="button"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleToggle(value, label, "delete");
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      color: "#6C757D",
                                    }}
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        },
                        IndicatorSeparator: () => null,
                      }}
                    />
                    <button
                      title={t("add_token")}
                      className="btn outline w-25 h-38 mr-2"
                      type="button"
                      onClick={() => setIsAddTokenModalOpen(true)}
                    >
                      <CirclePlusIcon />
                      <span className="ml-2">{t("add_token")}</span>
                    </button>
                  </ButtonGroup>
                </div>
              </>
            ) : null}
          </div>
          <div className="mt-5">
            <h5>{t("parameters")}</h5>
          </div>
          <div className="border-radius-4 table-responsive">
            <table className="table data-source-table">
              <colgroup>
                <col
                  span="1"
                  style={{
                    width: "47%",
                  }}
                />
                <col
                  span="1"
                  style={{
                    width: "47%",
                  }}
                />
                <col
                  span="1"
                  style={{
                    width: "3%",
                  }}
                />
                <col
                  span="1"
                  style={{
                    width: "3%",
                  }}
                />
              </colgroup>
              <thead className="bg-transparent border-0">
                <TableColumns />
              </thead>
              <tbody>
                {params.length > 0 &&
                  params.map((param, index) => (
                    <Parameter
                      key={param.external_data_param_id}
                      param={param}
                      setParams={setParams}
                      isLast={!params[index + 1]}
                      handleShowParamDeleteModal={handleShowParamDeleteModal}
                    />
                  ))}
              </tbody>
            </table>
          </div>

          <div className="mt-5">
            <h5>{t("headers")}</h5>
          </div>
          <div className="mb-5 border-radius-4 table-responsive">
            <table className="table data-source-table">
              <colgroup>
                <col
                  span="1"
                  style={{
                    width: "97%",
                  }}
                />
                <col
                  span="1"
                  style={{
                    width: "3%",
                  }}
                />
              </colgroup>
              <thead className="bg-transparent border-0">
                <TableColumns isHeaders={true} />
              </thead>
              <tbody>
                {headers.length > 0 &&
                  headers.map((header, index) => (
                    <Header
                      key={header.external_data_header_id}
                      setHeaders={setHeaders}
                      header={header}
                      handleShowHeaderDeleteModal={handleShowHeaderDeleteModal}
                      isLast={!headers[index + 1]}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {dama_service_id && (
        <>
          <div className="align-items-center row my-md-5">
            <div className="col-md col-12">
              <label>{t("data_hub_service")}</label>
              <br />
              {dataServiceDetails.title} by {dataServiceDetails.provider_title}
            </div>
          </div>

          <ServiceParameterTable
            serviceParams={data?.service_params || []} // Add null check and default
            params={params}
            setParams={setParams}
          />
        </>
      )}

      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_parameter")}
        message={t("delete_parameter_message")}
        open={isParameterDeleteModalOpen}
      />
      <DeleteConfirm
        handleClose={handleHeaderDeleteModalClose}
        handleConfirm={handleHeaderDeleteConfirm}
        title={t("delete_header")}
        message={t("delete_header_message")}
        open={isHeaderDeleteModalOpen}
      />
      {isAddTokenModalOpen && (
        <Token
          open={isAddTokenModalOpen}
          type={selectedOauth2.type}
          oauth2Token={modalOauth2Token}
          handleClose={setToken}
          handleSubmit={handleSubmit}
        />
      )}
      {isDeleteTokenModalOpen && (
        <DeleteConfirm
          handleClose={handleClose}
          handleConfirm={handleConfirmDeleteToken}
          title={t("token_delete")}
          message={
            <span>
              {t("do_you_want_to_delete")} <b>{selectedOauth2?.label}</b>{" "}
              {t("token")}?
            </span>
          }
          open={isDeleteTokenModalOpen}
        />
      )}
    </>
  );
};

DataSourceContent.propTypes = {
  data: PropTypes.object,
  isNew: PropTypes.bool,
  innerProps: PropTypes.any,
  label: PropTypes.any,
  value: PropTypes.any,
  dama_service_id: PropTypes.any,
};

export default DataSourceContent;
