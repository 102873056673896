// ScorecardEditorContent.js

import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { useTranslation } from "react-i18next";
import BinInput from "components/scorecards/BinInput";
import WeightInput from "components/scorecards/WeightInput";
import AutoSuggestInput from "../autosuggest";
import { MainContext } from "context/contexts";
import { getVectors } from "utility/utility";
import DeleteConfirm from "components/modals/DeleteConfirm";

const ScorecardEditorContent = ({
  scorecard,
  onScorecardChange,
  isRevision = false,
  hideFields = false,
}) => {
  const { autoSuggestData, setIsLoading, setIsEdited } =
    useContext(MainContext);

  const { t } = useTranslation();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPredictorId, setCurrentPredictorId] = useState(null);

  const [isBinDeleteModalOpen, setIsBinDeleteModalOpen] = useState(false);

  const [currentBinPredictorId, setCurrentBinPredictorId] = useState(null);
  const [currentBinId, setCurrentBinId] = useState(null);

  const [vectorsDataClone, setVectorsDataClone] = useState([]);

  const handleScorecardNameChange = (newName) => {
    onScorecardChange({ ...scorecard, name: newName });
  };

  const handleScorecardTitleChange = (newTitle) => {
    onScorecardChange({ ...scorecard, title: newTitle });
  };

  const handleScorecardAttributePathChange = (newAttributePath) => {
    onScorecardChange({ ...scorecard, attribute_path: newAttributePath });
  };

  const handleScorecardCalculationTypeChange = (newCalculationType) => {
    onScorecardChange({ ...scorecard, calculation_type: newCalculationType });
  };

  const handlePredictorNameChange = (predictorId, newName) => {
    const updatedPredictors = scorecard.predictors.map((predictor) =>
      predictor.scorecard_predictor_id === predictorId
        ? { ...predictor, name: newName }
        : predictor
    );
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
  };

  const handlePredictorWeightChange = (predictorId, newWeight) => {
    const updatedPredictors = scorecard.predictors.map((predictor) =>
      predictor.scorecard_predictor_id === predictorId
        ? { ...predictor, weight: newWeight || 1 }
        : predictor
    );
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
  };

  const handlePredictorAttributePathChange = (
    predictorId,
    newAttributePath
  ) => {
    const updatedPredictors = scorecard.predictors.map((predictor) =>
      predictor.scorecard_predictor_id === predictorId
        ? { ...predictor, attribute_path: newAttributePath }
        : predictor
    );
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
  };

  // Predictor weight vector change
  const handlePredictorWeightVectorChange = (predictorId, newWeightVector) => {
    const updatedPredictors = scorecard.predictors.map((predictor) =>
      predictor.scorecard_predictor_id === predictorId
        ? { ...predictor, weight_vector: newWeightVector }
        : predictor
    );
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
  };

  // Predictor weight_attribute_path change
  const handlePredictorWeightAttributePathChange = (
    predictorId,
    newWeightAttributePath
  ) => {
    const updatedPredictors = scorecard.predictors.map((predictor) =>
      predictor.scorecard_predictor_id === predictorId
        ? { ...predictor, weight_attribute_path: newWeightAttributePath }
        : predictor
    );
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
  };

  const onAttributePathInputChange = (predictorId) => (value) => {
    handlePredictorAttributePathChange(predictorId, value);
  };

  const handlePredictorDeleteModalOpen = (predictorId) => {
    setCurrentPredictorId(predictorId);
    setIsDeleteModalOpen(true);
  };

  const handlePredictorDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setCurrentPredictorId(null);
  };

  const handlePredictorDeleteConfirm = () => {
    handleDeletePredictor(currentPredictorId);
  };

  const handleBinDeleteModalOpen = (predictorId, binId) => {
    setCurrentBinId(binId);
    setCurrentBinPredictorId(predictorId);
    setIsBinDeleteModalOpen(true);
  };

  const handleBinDeleteModalClose = () => {
    setIsBinDeleteModalOpen(false);
    setCurrentBinId(null);
    setCurrentBinPredictorId(null);
  };

  const handleBinDeleteConfirm = () => {
    handleDeleteBin(currentBinPredictorId, currentBinId);
  };

  const handleDeletePredictor = (predictorId) => {
    const updatedPredictors = scorecard.predictors.filter(
      (predictor) => predictor.scorecard_predictor_id !== predictorId
    );
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
    setIsDeleteModalOpen(false);
  };

  const handleDeleteBin = (predictorId, binId) => {
    const updatedPredictors = scorecard.predictors.map((predictor) => {
      if (predictor.scorecard_predictor_id === predictorId) {
        // Filter out the bin to be deleted
        const updatedBins = predictor.bins.filter(
          (bin) => bin.scorecard_bin_id !== binId
        );
        return { ...predictor, bins: updatedBins };
      }
      return predictor;
    });

    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
    setIsBinDeleteModalOpen(false);
  };

  const handleBinChange = (predictorId, binId, updatedBinData) => {
    const updatedPredictors = scorecard.predictors.map((predictor) => {
      if (predictor.scorecard_predictor_id === predictorId) {
        const updatedBins = predictor.bins.map((bin) =>
          bin.scorecard_bin_id === binId ? { ...bin, ...updatedBinData } : bin
        );
        return { ...predictor, bins: updatedBins };
      }
      return predictor;
    });
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
  };

  const defaultBin = {
    scorecard_bin_id: "new_" + Math.random().toString(36).substr(2, 9),
    operator: "equal_to", // Default operator
    value_a: "", // Default value
    value_b: "", // Default value
    name: "",
    score: 0, // Default score
  };

  const handleAddPredictor = () => {
    const newPredictorId = "new_" + Math.random().toString(36).substr(2, 9);
    const newPredictor = {
      scorecard_predictor_id: newPredictorId,
      name: "",
      weight: 1,
      attribute_path: "",
      bins: [
        {
          ...defaultBin,
          scorecard_bin_id: "new_" + Math.random().toString(36).substr(2, 9),
        },
      ],
    };
    onScorecardChange({
      ...scorecard,
      predictors: [...scorecard.predictors, newPredictor],
    });
  };

  const handleAddBin = (predictorId) => {
    const updatedPredictors = scorecard.predictors.map((predictor) => {
      if (predictor.scorecard_predictor_id === predictorId) {
        return {
          ...predictor,
          bins: [
            ...predictor.bins,
            {
              ...defaultBin,
              scorecard_bin_id:
                "new_" + Math.random().toString(36).substr(2, 9),
            },
          ],
        };
      }
      return predictor;
    });
    onScorecardChange({ ...scorecard, predictors: updatedPredictors });
  };

  useEffect(() => {
    if (scorecard.predictors) {
      scorecard.predictors.forEach((predictor) => {
        if (!predictor.bins || predictor.bins.length === 0) {
          handleAddBin(predictor.scorecard_predictor_id);
        }
      });
    }
  }, [scorecard.predictors]); // Added dependency to handle dynamic changes

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  return (
    <>
      <div>
        {!hideFields && (
          <>
            <div className="row mb-4">
              <div className="col-md col-12">
                <label>{t("title_capitalized")}</label>
                <input
                  type="text"
                  className="form-control border-radius-4"
                  value={scorecard.title || ""}
                  placeholder={t("scorecard_title")}
                  onChange={(e) => handleScorecardTitleChange(e.target.value)}
                  style={{ marginRight: "10px" }}
                  disabled={isRevision ? "disabled" : ""}
                />
              </div>
              <div className="col-md col-12">
                <label>{t("identifier")}</label>
                <input
                  type="text"
                  className="form-control border-radius-4"
                  value={scorecard.name || ""}
                  placeholder={t("scorecard_identitifier")}
                  onChange={(e) => handleScorecardNameChange(e.target.value)}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(
                      /[^a-zA-Z0-9_-]/g,
                      ""
                    );
                  }}
                  pattern="[a-zA-Z0-9_\-]+"
                  title={t("only_letters_numbers_underscore_hyphen")}
                  disabled={isRevision ? "disabled" : ""}
                  required
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md col-12">
                <label>{t("attribute_path")}</label>
                <input
                  type="text"
                  className={`form-control border-radius-4 ${
                    scorecard.attribute_path &&
                    !scorecard.attribute_path.startsWith("$.")
                      ? "is-invalid"
                      : ""
                  }`}
                  value={scorecard.attribute_path || ""}
                  placeholder={t("attribute_path")}
                  onChange={(e) =>
                    handleScorecardAttributePathChange(e.target.value)
                  }
                  style={{ marginRight: "10px" }}
                  disabled={isRevision ? "disabled" : ""}
                />
              </div>

              <div className="col-md col-12">
                <label>{t("calculation_type")}</label>
                <select
                  className="form-control border-radius-4"
                  value={scorecard.calculation_type}
                  onChange={(e) =>
                    handleScorecardCalculationTypeChange(e.target.value)
                  }
                  disabled={isRevision ? "disabled" : ""}
                >
                  <option value="sum">{t("scorecard_calculation_sum")}</option>
                  <option value="logistic">
                    {t("scorecard_calculation_logistic_regression")}
                  </option>
                </select>
              </div>
            </div>
          </>
        )}
        {/* Predictors Table */}
        <div className="table-responsive mb-3 decision-table-settings border-radius-4">
          <table
            className="table-responsive table table-pretty vertical-align-middle mb-10"
            style={{ overflow: "visible" }}
          >
            <colgroup>
              <col span="1" style={{ width: "40%" }} />
              <col span="1" style={{ width: "20%" }} />
              <col span="1" style={{ width: "20%" }} />
              <col span="1" style={{ width: "10%" }} />
              {!isRevision && <col span="1" style={{ width: "2%" }} />}
              <col span="1" style={{ width: "10%" }} />
              {!isRevision && <col span="1" style={{ width: "2%" }} />}
            </colgroup>
            <thead>
              <tr>
                <th>{t("predictor")}</th>
                <th>{t("bin_name")}</th>
                <th>{t("bin_range")}</th>
                <th>{t("score")}</th>
                {!isRevision && <th>{t("delete")}</th>}
                <th>{t("predictor_weight")}</th>
                {!isRevision && <th>{t("delete")}</th>}
              </tr>
            </thead>
            <tbody>
              {scorecard?.predictors
                ?.filter((predictor) => predictor != null) // Filter out null or undefined elements
                .map((predictor) => {
                  return (
                    <React.Fragment key={predictor.scorecard_predictor_id}>
                      {predictor.bins &&
                        predictor?.bins.map((bin, index) => (
                          <tr key={bin.scorecard_bin_id}>
                            {/* Predictor Details */}
                            {index === 0 && (
                              <td
                                rowSpan={
                                  isRevision
                                    ? predictor.bins.length
                                    : predictor.bins.length + 1
                                }
                              >
                                <div>
                                  <input
                                    type="text"
                                    className="form-control form-control-plaintext"
                                    value={predictor.name || ""}
                                    placeholder={t("predictor_name")}
                                    disabled={isRevision ? "disabled" : ""}
                                    onChange={(e) =>
                                      handlePredictorNameChange(
                                        predictor.scorecard_predictor_id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  {!isRevision && (
                                    <AutoSuggestInput
                                      vectorsData={vectorsDataClone}
                                      defaultValue={
                                        predictor.attribute_path || ""
                                      }
                                      placeholder={t("attribute_path")}
                                      inputName={
                                        "attribute_path" +
                                        predictor.scorecard_predictor_id
                                      }
                                      tableMode={true}
                                      onInputChange={onAttributePathInputChange(
                                        predictor.scorecard_predictor_id
                                      )}
                                    />
                                  )}
                                  {isRevision && (
                                    <input
                                      type="text"
                                      className="form-control form-control-plaintext"
                                      value={predictor.attribute_path || ""}
                                      placeholder={t("attribute_path")}
                                      disabled={isRevision ? "disabled" : ""}
                                    />
                                  )}
                                </div>
                              </td>
                            )}

                            {/* Bin Name */}
                            <td className="p-0 m-0">
                              <input
                                type="text"
                                className="form-control m-0 border-0 rounded-0"
                                placeholder={t("bin_name")}
                                value={bin.name}
                                disabled={isRevision ? "disabled" : ""}
                                onChange={(e) =>
                                  handleBinChange(
                                    predictor.scorecard_predictor_id,
                                    bin.scorecard_bin_id,
                                    { ...bin, name: e.target.value }
                                  )
                                }
                              />
                            </td>

                            {/* Bin Range */}
                            <td className="p-0 m-0">
                              <BinInput
                                key={bin.scorecard_bin_id}
                                bin={bin}
                                scorecardPredictorId={
                                  predictor.scorecard_predictor_id
                                }
                                handleBinChange={handleBinChange}
                                isRevision={isRevision}
                              />
                            </td>

                            {/* Score */}
                            <td className="p-0 m-0">
                              <input
                                type="number"
                                className="form-control m-0 border-0 rounded-0"
                                value={bin.score}
                                disabled={isRevision ? "disabled" : ""}
                                onChange={(e) =>
                                  handleBinChange(
                                    predictor.scorecard_predictor_id,
                                    bin.scorecard_bin_id,
                                    {
                                      ...bin,
                                      score: parseFloat(e.target.value),
                                    }
                                  )
                                }
                              />
                            </td>

                            {/* Delete Bin */}
                            {!isRevision && (
                              <td className="py-0">
                                {predictor.bins.length > 1 ? (
                                  <button
                                    className="border-radius-4 border-0 btn btn-outline-light mr-3 option-delete"
                                    onClick={() =>
                                      handleBinDeleteModalOpen(
                                        predictor.scorecard_predictor_id,
                                        bin.scorecard_bin_id
                                      )
                                    }
                                    title={t("Delete")}
                                    type="button"
                                  >
                                    <TrashIcon
                                      style={{
                                        color: "#6C757D",
                                      }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="border-radius-4 border-0 btn btn-outline-light mr-3 option-delete"
                                    disabled={true}
                                    title={t("Delete")}
                                    type="button"
                                  >
                                    {" "}
                                  </button>
                                )}
                              </td>
                            )}

                            {/* Predictor Weight */}
                            {index === 0 && (
                              <>
                                <td
                                  rowSpan={
                                    isRevision
                                      ? predictor.bins.length
                                      : predictor.bins.length + 1
                                  }
                                >
                                  <WeightInput
                                    weight={predictor.weight || 1}
                                    disabled={!!isRevision}
                                    onWeightChange={(newWeight) =>
                                      handlePredictorWeightChange(
                                        predictor.scorecard_predictor_id,
                                        newWeight
                                      )
                                    }
                                    onPathChange={(newPath) =>
                                      handlePredictorWeightAttributePathChange(
                                        predictor.scorecard_predictor_id,
                                        newPath
                                      )
                                    }
                                    onVectorChange={(newVector) =>
                                      handlePredictorWeightVectorChange(
                                        predictor.scorecard_predictor_id,
                                        newVector
                                      )
                                    }
                                    vector={predictor.weight_vector}
                                    vectorsData={vectorsDataClone}
                                    weight_attribute_path={
                                      predictor.weight_attribute_path
                                    }
                                  />
                                </td>
                                {!isRevision &&
                                  scorecard.predictors.length > 1 && (
                                    <td
                                      rowSpan={
                                        isRevision
                                          ? predictor.bins.length
                                          : predictor.bins.length + 1
                                      }
                                    >
                                      <button
                                        className="border-radius-4 border-0 btn btn-outline-light option-delete"
                                        title={t("delete")}
                                        onClick={() =>
                                          handlePredictorDeleteModalOpen(
                                            predictor.scorecard_predictor_id
                                          )
                                        }
                                        type="button"
                                      >
                                        <TrashIcon
                                          style={{
                                            color: "#6C757D",
                                          }}
                                        />
                                      </button>
                                    </td>
                                  )}
                              </>
                            )}
                          </tr>
                        ))}
                      {!isRevision && (
                        <tr>
                          <td colSpan="4" className="p-0">
                            <div className="d-flex justify-content-center add-row-bg p-1 m-0">
                              <button
                                onClick={() =>
                                  handleAddBin(predictor.scorecard_predictor_id)
                                }
                                className="btn outline m-0"
                                type="button"
                                title={t("add_bin")}
                              >
                                <PlusIcon
                                  style={{
                                    filter: "brightness(0.5)",
                                  }}
                                />
                                <span className="ml-2">{t("add_bin")}</span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              {!isRevision && (
                <tr>
                  <td colSpan="7" className="p-0">
                    <div className="d-flex justify-content-center add-row-bg py-3">
                      <button
                        className="btn outline"
                        onClick={handleAddPredictor}
                        title={t("add_predictor")}
                        type="button"
                      >
                        <PlusIcon
                          style={{
                            filter: "brightness(0.5)",
                          }}
                        />
                        <span className="ml-2">{t("add_predictor")}</span>
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Delete Confirm Modals */}
      <DeleteConfirm
        handleClose={handlePredictorDeleteModalClose}
        handleConfirm={handlePredictorDeleteConfirm}
        title={t("delete_scorecard_predictor")}
        message={t("do_you_want_to_delete_scorecard_predictor")}
        open={isDeleteModalOpen}
      />
      <DeleteConfirm
        handleClose={handleBinDeleteModalClose}
        handleConfirm={handleBinDeleteConfirm}
        title={t("delete_bin")}
        message={t("do_you_want_to_delete_bin")}
        open={isBinDeleteModalOpen}
      />
    </>
  );
};

ScorecardEditorContent.propTypes = {
  scorecard: PropTypes.shape({
    scorecard_id: PropTypes.any,
    name: PropTypes.string,
    title: PropTypes.string,
    attribute_path: PropTypes.string,
    calculation_type: PropTypes.string,
    predictors: PropTypes.arrayOf(
      PropTypes.shape({
        scorecard_predictor_id: PropTypes.string,
        name: PropTypes.string,
        weight: PropTypes.number,
        attribute_path: PropTypes.string,
        weight_vector: PropTypes.number, // Ensure weight_vector exists
        weight_attribute_path: PropTypes.string, // Ensure weight_attribute_path exists
        bins: PropTypes.arrayOf(
          PropTypes.shape({
            scorecard_bin_id: PropTypes.string,
            operator: PropTypes.string,
            value_a: PropTypes.string,
            value_b: PropTypes.string,
            name: PropTypes.string,
            score: PropTypes.number,
          })
        ),
      })
    ),
  }).isRequired,
  onScorecardChange: PropTypes.func.isRequired,
  vectorsData: PropTypes.array,
  isRevision: PropTypes.bool,
  hideFields: PropTypes.bool,
};

export default ScorecardEditorContent;
