import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { MainContext } from "context/contexts";
import SubFlowAttributes from "components/workflow/SubFlowAttributes";
import SubFlowStepOption from "components/workflow/SubFlowStepOption";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteStepOptionParamRequest,
  deleteStepOptionRequest,
} from "redux/workflows/action";
import usePrevious from "utility/hooks/usePrevious";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import StepOptionEdit from "components/modals/workflow/StepOptionEdit";
import { ReactComponent as ExternalLinkSmallIcon } from "assets/icons/external_link.svg";
import { ReactComponent as PasteIcon } from "assets/icons/union-paste.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { getVectors } from "utility/utility";
import { useTranslation } from "react-i18next";
import CustomSelect from "../CustomSelect";

const StepContent = (props) => {
  const { t } = useTranslation();

  const {
    stepOptions,
    label1 = t("rule_set"),
    options = [],
    optionTypeId,
    linkTo,
    conditionTypes = [],
  } = props;
  const dispatch = useDispatch();
  const { id, revisionId } = useParams();

  const { vectorsData, setIsLoading, setIsEdited, autoSuggestData } =
    useContext(MainContext);

  const { isDeletedStepOptionParamSuccess, isDeletedStepOptionSuccess } =
    useSelector((state) => state.workflows);

  const prevIsDeletedStepOptionParamSuccess = usePrevious(
    isDeletedStepOptionParamSuccess
  );
  const prevIsDeletedStepOptionSuccess = usePrevious(
    isDeletedStepOptionSuccess
  );

  const [isOptionDeleteModalOpen, setIsOptionDeleteModalOpen] = useState(false);
  const [isParameterDeleteModalOpen, setIsParameterDeleteModalOpen] =
    useState(false);

  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [selectedParamId, setSelectedParamId] = useState(null);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [stepOptionsData, setStepOptionsData] = useState(stepOptions);
  const [openedOption, setOpenedOption] = useState(null);
  const [selectOptions, setSelectOptions] = useState([]);
  const [optionId, setOptionId] = useState(
    stepOptions && stepOptions.wf_step_option_id
      ? stepOptions[optionTypeId]
      : optionTypeId !== "external_data_id"
      ? null
      : options.length > 0 && options[0][optionTypeId]
  );

  const [optionEditMode, setOptionEditMode] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isOptionEditModalOpen, setIsOptionEditModalOpen] = useState(false);
  const [selectedStepOption, setSelectedStepOption] = useState({});

  const localSelectedOption = JSON.parse(
    localStorage.getItem("selectedOption")
  );

  useEffect(() => {
    if (
      isDeletedStepOptionParamSuccess &&
      prevIsDeletedStepOptionParamSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("decision_option_attribute_deleted"), ToastOptions);
    }
  }, [isDeletedStepOptionParamSuccess]);

  useEffect(() => {
    if (options.length) {
      setSelectOptions(() => {
        const optionsClone = structuredClone(options);
        const data = [
          {
            value: "none",
            label: "None",
          },
        ];
        optionsClone.map((optionClone) => {
          data.push({
            value:
              optionClone[
                optionTypeId.includes("sub") ? "workflow_id" : optionTypeId
              ],
            label: optionClone.title,
          });
        });
        return data;
      });
    }
  }, [options]);

  useEffect(() => {
    if (
      isDeletedStepOptionSuccess &&
      prevIsDeletedStepOptionSuccess === false
    ) {
      setIsLoading(false);
      toast.warning(t("decision_option_deleted"), ToastOptions);
    }
  }, [isDeletedStepOptionSuccess]);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  useEffect(() => {
    const saveStepBtn = document.querySelector(".saveStepBtn");
    if (saveStepBtn) {
      if (!optionEditMode) {
        saveStepBtn.disabled = "disabled";
      } else {
        saveStepBtn.disabled = "";
      }
    }
  }, [optionEditMode]);

  const handleAddOption = () => {
    if (options?.length > 0) {
      let optionsClone = structuredClone(stepOptionsData);
      if (!optionsClone) {
        optionsClone = [];
      }
      const option = options.find(
        (option) => option.external_data_id == parseInt(optionId)
      );
      const optionData = {
        attributes: option?.params ? option.params : [],
        decision_table_id: null,
        external_data_id: optionId,
        fce_id: null,
        model_id: null,
        name: option.name,
        rule_set_id: null,
        sub_workflow_id: null,
        scorecard_id: null,
        decision_tree_id: null,
        title: option.title,
        wf_step_option_id: `new_${Math.round(Math.random() * 10000)}`,
        workflow_step_link_id: null,
        dama_service_id: option.dama_service_id || null,
      };

      setIsEdited(true);
      optionsClone.push(optionData);
      setStepOptionsData(optionsClone);
    }
  };

  const handleAddParameter = (optionId) => {
    const optionsClone = structuredClone(stepOptionsData);
    const option = optionsClone.find(
      (option) => option.wf_step_option_id == optionId
    );
    const parameter = {
      attribute_vector: 0,
      name: "",
      path: "",
      value: "",
      dama_service_param_id: null,
      wf_step_opt_condition_type_id: `new_${Math.round(Math.random() * 10000)}`,
      wf_step_option_attribute_id: `new_${Math.round(Math.random() * 10000)}`,
    };
    if (!option.attributes) {
      option.attributes = [];
    }
    option.attributes.push(parameter);
    setIsEdited(true);

    setStepOptionsData(optionsClone);
  };

  const deleteParam = (paramId) => {
    const optionsClone = structuredClone(stepOptionsData);
    const option = optionsClone.find(
      (option) => option.wf_step_option_id === selectedOptionId
    );
    option.attributes = option.attributes.filter(
      (attr) => attr.wf_step_option_attribute_id !== paramId
    );
    setStepOptionsData(optionsClone);

    if (typeof paramId === "number") {
      setIsLoading(true);
      dispatch(deleteStepOptionParamRequest({ id, paramId }));
    }
  };

  const handleShowParamDeleteModal = (selectedParamId, selectedOptionId) => {
    setSelectedParamId(selectedParamId);
    setSelectedOptionId(selectedOptionId);
    setIsParameterDeleteModalOpen(true);
  };

  const handleClose = () => {
    setSelectedParamId(null);
    setIsParameterDeleteModalOpen(false);
  };

  const handleConfirm = () => {
    setIsParameterDeleteModalOpen(false);
    deleteParam(selectedParamId);
  };

  const deleteOption = (optionId) => {
    const optionsClone = structuredClone(stepOptionsData);
    const options = optionsClone.filter(
      (option) => option.wf_step_option_id !== selectedOptionId
    );
    setStepOptionsData(options);

    if (typeof optionId === "number") {
      setIsLoading(true);
      dispatch(deleteStepOptionRequest({ id, optionId }));
    }
  };

  const handleShowOptionDeleteModal = (selectedOptionId) => {
    setSelectedOptionId(selectedOptionId);
    setIsOptionDeleteModalOpen(true);
  };

  const handleOptionDeleteClose = () => {
    setSelectedOptionId(null);
    setIsOptionDeleteModalOpen(false);
  };

  const handleOptionDeleteConfirm = () => {
    setIsOptionDeleteModalOpen(false);
    deleteOption(selectedOptionId);
  };

  const handleShowOptionEditModal = (option) => {
    setSelectedStepOption(option);
    setIsOptionEditModalOpen(true);
  };

  const handleOptionEditClose = () => {
    setSelectedStepOption({});
    setIsOptionEditModalOpen(false);
  };

  const handleOptionEditConfirm = (title, name, id) => {
    const data = structuredClone(stepOptionsData);
    const option = data.find((option) => option.wf_step_option_id == id);
    option.title = title;
    option.name = name;

    setStepOptionsData(data);
    setIsOptionEditModalOpen(false);
  };

  const handlePasteOptions = () => {
    setIsCopied(false);
    setStepOptionsData((prevState) => {
      const cloneOfOption = JSON.parse(localStorage.getItem("selectedOption"));
      cloneOfOption.wf_step_option_id = `new_${parseInt(
        Date.now() * Math.random()
      )}`;
      cloneOfOption.attributes?.map((attr) => {
        attr.wf_step_option_attribute_id = `new_${parseInt(
          Date.now() * Math.random()
        )}`;
      });
      if (prevState && prevState.length) {
        return [...prevState, cloneOfOption];
      } else {
        return [cloneOfOption];
      }
    });
  };

  const getOption = () => {
    return options?.find(
      (option) =>
        option[optionTypeId.includes("sub") ? "workflow_id" : optionTypeId] ==
        optionId
    );
  };

  const handleChangeOptionEditMode = () => {
    if (optionId) {
      setOptionEditMode(!optionEditMode);
    }
  };

  const handleChangeOption = (e) => {
    setOptionEditMode(true);
    setOptionId(e.value === "none" ? null : e.value);
    setIsEdited(true);
  };

  const customStyles = {
    singleValue: (styles) => ({
      ...styles,
      color: !optionId ? "#dc3545" : "#6C757D",
      marginLeft: "10px",
    }),
  };

  const getSelectValue = () => {
    return !optionId || !getOption()
      ? selectOptions[0]
      : selectOptions?.find((data) => data.value == optionId);
  };

  useEffect(() => {
    /* if external data, then take stepOptionsData external_data_id and match it in options, to get dama_service_id and assign it to the stepOptionsData */
    if (optionTypeId === "external_data_id") {
      const optionsClone = structuredClone(stepOptionsData);
      optionsClone.map((option) => {
        const matchedOption = options.find(
          (opt) => opt.external_data_id == option.external_data_id
        );
        if (matchedOption) {
          option.dama_service_id = matchedOption.dama_service_id;
        }
      });
      setStepOptionsData(optionsClone);
    }
  }, [options]);

  // Assuming stepOptions.attributes is an array of attribute objects
  const preparedToBeOrderedAttributes = stepOptions?.attributes || [];

  // Separate execution_type attribute and other attributes
  const executionTypeAttr = preparedToBeOrderedAttributes.find(
    (attr) => attr.name === "execution_type"
  );
  const otherAttrs = preparedToBeOrderedAttributes.filter(
    (attr) => attr.name !== "execution_type"
  );

  // Create a new array with execution_type first
  const orderedAttributes = executionTypeAttr
    ? [executionTypeAttr, ...otherAttrs]
    : otherAttrs;

  return (
    <>
      <div className="mb-29">
        {optionTypeId !== "external_data_id" && optionTypeId !== "fork" && (
          <>
            <label>
              {t("current")} {label1}:
            </label>
            <div className="input-group mb-1 flex-nowrap">
              <input
                type="hidden"
                name={
                  optionId
                    ? `option[${
                        stepOptions
                          ? stepOptions.wf_step_option_id
                          : `new_${Math.round(Math.random() * 10000)}`
                      }][${optionTypeId}]`
                    : ""
                }
                defaultValue={!optionId ? "" : optionId}
                key={optionId}
              />
              {optionEditMode || !optionId || !getOption() ? (
                <div
                  className="input-group mb-1 mt-1 w-80-5"
                  title={t("step_options")}
                >
                  <CustomSelect
                    defaultValue={getSelectValue}
                    key={selectOptions.length}
                    options={selectOptions}
                    handleChange={handleChangeOption}
                    isSearchable={false}
                    isDisabled={revisionId && "disabled"}
                    selectWidth="w-100"
                    selectStyle={customStyles}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  {optionTypeId && (
                    <input
                      type="hidden"
                      name={optionTypeId}
                      value={!optionId ? "" : optionId}
                    />
                  )}
                </div>
              ) : optionId && !optionEditMode ? (
                <div
                  className="align-items-center d-flex ml-2 col-9 cursor-pointer"
                  title={t("item_name")}
                >
                  {getOption()?.title}
                  <a
                    title={t("item_new_blank")}
                    target="_blank"
                    rel="noreferrer"
                    href={`/${linkTo}/${
                      getOption()
                        ? getOption()[
                            optionTypeId.includes("sub")
                              ? "workflow_id"
                              : optionTypeId
                          ]
                        : null
                    }`}
                  >
                    <button
                      className="bg-white border-0 btn ml-2 outline"
                      type="button"
                    >
                      <ExternalLinkSmallIcon />
                    </button>
                  </a>
                </div>
              ) : null}

              <div className="align-items-center input-group-append mx-3">
                <button
                  title={t("edit_option")}
                  disabled={!!revisionId || !optionId}
                  type="button"
                  className="align-items-center bg-white border-0 btn connected-btn d-flex outline py-2 w-100"
                  onClick={handleChangeOptionEditMode}
                >
                  <span className="connected mr-2" />
                  {optionEditMode || !optionId ? t("set") : t("change")}
                </button>
              </div>
            </div>
          </>
        )}

        {linkTo === "workflows" && optionId && (
          <div className="bg-gray border-radius-4 mt-3 p-3">
            {orderedAttributes?.map((attr, index) => (
              <SubFlowAttributes
                key={index}
                attr={attr}
                vectorsDataClone={vectorsDataClone}
                stepOptionId={stepOptions.wf_step_option_id}
              />
            ))}
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center">
          {optionTypeId === "external_data_id" ? (
            <>
              <label>{t("data_sources")}</label>
            </>
          ) : options?.length > 0 || stepOptions?.length > 0 ? (
            optionTypeId === "fork" ? (
              <label>{t("options")}</label>
            ) : null
          ) : ["fork", "subflow"].includes(optionTypeId) ? (
            <p className="text-dark m-0">
              {optionTypeId === "fork"
                ? t("no_options")
                : t("no_decision_flows")}
            </p>
          ) : (
            ""
          )}
          {optionTypeId === "external_data_id" &&
            (localSelectedOption || isCopied) && (
              <button
                className="align-items-center btn d-flex mb-3 primary"
                type="button"
                onClick={handlePasteOptions}
              >
                <PasteIcon /> <span className="ml-3">{t("paste")}</span>
              </button>
            )}
        </div>

        {optionTypeId === "external_data_id" || optionTypeId === "fork" ? (
          <div className="accordion mb-4" id="option-details">
            {stepOptionsData?.map((option) => (
              <SubFlowStepOption
                key={
                  option.wf_step_option_id
                    ? option.wf_step_option_id
                    : `new_${Math.round(Math.random() * 100)}`
                }
                option={option}
                setIsCopied={setIsCopied}
                conditionTypes={conditionTypes}
                handleShowParamDeleteModal={handleShowParamDeleteModal}
                handleShowOptionDeleteModal={handleShowOptionDeleteModal}
                handleAddParameter={handleAddParameter}
                handleShowOptionEditModal={handleShowOptionEditModal}
                isFork={optionTypeId === "fork"}
                setOpenedOption={setOpenedOption}
                openedOption={openedOption}
              />
            ))}
          </div>
        ) : null}
        {optionTypeId === "external_data_id" && (
          <div className="input-group mb-3 mt-3">
            <select
              title={t("data_source_options")}
              disabled={!!revisionId}
              className="form-control data-sources"
              defaultValue={!optionId ? "" : optionId}
              onChange={(e) => setOptionId(e.target.value)}
            >
              {options?.length === 0 ? (
                <option>{t("no_options")}</option>
              ) : null}
              {options?.map((option, index) => (
                <option
                  key={index}
                  value={
                    option[
                      optionTypeId.includes("sub")
                        ? "workflow_id"
                        : optionTypeId
                    ]
                  }
                >
                  {option.title}
                </option>
              ))}
            </select>
            {optionTypeId && (
              <input type="hidden" name={optionTypeId} value={optionTypeId} />
            )}
            <div className="input-group-append">
              <button
                title={t("add_data_source")}
                disabled={!!revisionId || options.length === 0}
                type="button"
                className="add-option align-items-center btn d-flex justify-content-center"
                onClick={handleAddOption}
              >
                <PlusIcon className="mr-2" /> {t("add")}
                {optionTypeId === "external_data_id" ? "" : "option"}
              </button>
            </div>
          </div>
        )}
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        title={t("delete_parameter")}
        message={t("delete_parameter_message")}
        open={isParameterDeleteModalOpen}
      />
      <DeleteConfirm
        handleClose={handleOptionDeleteClose}
        handleConfirm={handleOptionDeleteConfirm}
        title={t("delete_option")}
        message={t("delete_option_message")}
        open={isOptionDeleteModalOpen}
      />
      <StepOptionEdit
        handleClose={handleOptionEditClose}
        handleConfirm={handleOptionEditConfirm}
        open={isOptionEditModalOpen}
        data={selectedStepOption}
      />
    </>
  );
};

StepContent.propTypes = {
  stepOptions: PropTypes.any,
  options: PropTypes.array,
  label1: PropTypes.string,
  label2: PropTypes.string,
  optionTypeId: PropTypes.string,
  linkTo: PropTypes.string,
  name: PropTypes.any,
  conditionTypes: PropTypes.array,
};

export default StepContent;
