import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as EditIcon } from "assets/icons/edit-small.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as CancelIcon } from "assets/icons/close_modal.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { useTranslation } from "react-i18next";

const AttributeRow = ({
  index,
  style,
  attribute,
  disabledInputs,
  changeValue,
  handleConfirmEditAttr,
  handleShowAttributeEditModal,
  handleShowVectorAttrDeleteModal,
  handleShowAttributeSettings, // new prop callback
}) => {
  const { t } = useTranslation();

  const [originalValues] = React.useState({
    mapping: attribute?.mapping,
    dummy_value: attribute?.dummy_value,
  });

  const handleCancel = (e) => {
    e.preventDefault();
    // Reset form values to originals
    const form = e.target.closest("form");
    form.querySelector(`input[name="mapping-${index}"]`).value =
      originalValues.mapping;
    form.querySelector(`input[name="dummy_value-${index}"]`).value =
      originalValues.dummy_value;
    // Exit inline edit mode
    handleShowAttributeEditModal(attribute, attribute.vector_attribute_id);
  };

  return (
    <form
      onSubmit={(e) =>
        handleConfirmEditAttr(e, attribute.vector_attribute_id, index)
      }
      style={style}
      className="d-flex align-items-center attribute-row"
    >
      <div style={{ width: "45%", paddingRight: "10px", paddingLeft: "5px" }}>
        <input
          onChange={changeValue}
          name={`mapping-${index}`}
          className="form-control form-control-sm form-control-plaintext"
          type="text"
          placeholder={t("attribute_mapping")}
          disabled={!disabledInputs.includes(attribute.vector_attribute_id)}
          defaultValue={attribute?.mapping}
          required
        />
      </div>
      <div style={{ width: "45%", paddingRight: "10px" }}>
        <input
          name={`dummy_value-${index}`}
          className="form-control form-control-sm form-control-plaintext"
          type="text"
          placeholder={attribute?.dummy_value ? t("attribute_mapping") : ""}
          disabled={!disabledInputs.includes(attribute.vector_attribute_id)}
          defaultValue={attribute?.dummy_value}
          required
        />
      </div>
      <div
        className="d-flex align-items-center"
        style={{ width: "10%", minWidth: "70px", justifyContent: "flex-end" }}
      >
        <span className="ml-0 clickable">
          {disabledInputs.includes(attribute.vector_attribute_id) ? (
            <>
              <button
                title={t("save_attribute")}
                type="submit"
                className="btn outline p-0 m-0 border-0"
              >
                <SaveIcon />
              </button>
              <button
                title={t("cancel")}
                type="button"
                className="btn outline p-0 m-0 border-0 ml-2"
                onClick={handleCancel}
              >
                <CancelIcon />
              </button>
            </>
          ) : (
            <>
              <button
                title={t("edit_attribute_inline")}
                onClick={() =>
                  handleShowAttributeEditModal(
                    { ...attribute, index },
                    attribute.vector_attribute_id
                  )
                }
                className="btn outline p-0 m-0 border-0"
              >
                <EditIcon />
              </button>
              <button
                title={t("edit_attribute_full")}
                onClick={() => handleShowAttributeSettings(attribute)}
                className="btn outline p-0 m-0 border-0 ml-2"
              >
                <SettingsIcon />
              </button>
            </>
          )}
          <button
            title={t("delete_attribute")}
            className="btn outline p-0 m-0 border-0 ml-2"
            onClick={() => handleShowVectorAttrDeleteModal(attribute)}
          >
            <DeleteIcon />
          </button>
        </span>
      </div>
    </form>
  );
};

AttributeRow.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
  disabledInputs: PropTypes.array.isRequired,
  changeValue: PropTypes.func.isRequired,
  handleConfirmEditAttr: PropTypes.func.isRequired,
  handleShowAttributeEditModal: PropTypes.func.isRequired,
  handleShowVectorAttrDeleteModal: PropTypes.func.isRequired,
  handleShowAttributeSettings: PropTypes.func.isRequired, // new prop
};

export { AttributeRow };
