// src/components/BatchTestProgress.jsx
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BatchTestProgress = () => {
  const { batchTest } = useSelector((state) => state.releases);

  const { t } = useTranslation();

  if (batchTest.total === 0) return null;

  const progressPercentage = (
    (batchTest.completed / batchTest.total) *
    100
  ).toFixed(2);

  return (
    <div className="batch-test-progress mt-4">
      <h4>{t("batch_testing_progress")}</h4>
      <div className="progress">
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressPercentage}%` }}
          aria-valuenow={batchTest.completed}
          aria-valuemin="0"
          aria-valuemax={batchTest.total}
        >
          {progressPercentage}%
        </div>
      </div>
      <p className="mt-2">
        {t("completed")} {batchTest.completed} / {batchTest.total}
      </p>
    </div>
  );
};

export default BatchTestProgress;
