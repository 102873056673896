// src/utils/indexedDB.js
import Dexie from "dexie";
import { toInteger } from "lodash";

const BatchTestDB = new Dexie("BatchTestDB");
BatchTestDB.version(1).stores({
  results: "++id, releaseId, input, output, status, timestamp",
  pendingTests: "++id, releaseId, input, status",
});

export const addResultToDB = async (releaseId, result) => {
  await BatchTestDB.results.add({
    releaseId: String(releaseId),
    input: result.input,
    output: result.output,
    status: result.status,
    timestamp: new Date(),
  });
};

export const getResultsFromDB = async (releaseId) => {
  return await BatchTestDB.results
    .where("releaseId")
    .equals(releaseId)
    .toArray();
};

export const clearResultsFromDB = async (releaseId) => {
  return await BatchTestDB.results
    .where("releaseId")
    .equals(releaseId)
    .delete();
};

export const addPendingTestToDB = async (releaseId, tests) => {
  const pendingTests = tests.map((test) => ({
    releaseId: String(releaseId),
    input: test,
    status: "pending",
    timestamp: new Date(),
  }));

  await BatchTestDB.pendingTests.bulkAdd(pendingTests);
};

export const getPendingTestsFromDB = async (releaseId, offset, limit) => {
  releaseId = String(releaseId);
  const test = await BatchTestDB.pendingTests
    .where("releaseId")
    .equals(releaseId)
    .and((item) => item.status === "pending")
    .offset(offset)
    .limit(limit)
    .toArray();

  return test;
};

export const clearPendingTestsFromDB = async (releaseId) => {
  return await BatchTestDB.pendingTests
    .where("releaseId")
    .equals(releaseId)
    .delete();
};

export const markTestAsProcessed = async (id) => {
  await BatchTestDB.pendingTests.update(id, { status: "processed" });
};

export const clearProcessedTests = async (releaseId) => {
  releaseId = String(releaseId);

  return await BatchTestDB.pendingTests
    .where("releaseId")
    .equals(releaseId)
    .and((item) => item.status === "processed")
    .delete();
};

export const countPendingTests = async (releaseId) => {
  releaseId = String(releaseId);

  return await BatchTestDB.pendingTests
    .where("releaseId")
    .equals(releaseId)
    .and((item) => item.status === "pending")
    .count();
};

// Add new method for paginated results
export const getPaginatedResultsFromDB = async (
  releaseId,
  page = 0,
  pageSize = 50
) => {
  const offset = page * pageSize;
  const results = await BatchTestDB.results
    .where("releaseId")
    .equals(String(releaseId))
    .offset(offset)
    .limit(pageSize)
    .toArray();

  const totalCount = await BatchTestDB.results
    .where("releaseId")
    .equals(String(releaseId))
    .count();

  return { results, totalCount };
};

const generateJsonPaths = (obj, parentPath = "$") => {
  let paths = [];

  const traverse = (obj, currentPath) => {
    if (Array.isArray(obj)) {
      // For arrays, we'll show both array access and first element access
      paths.push(currentPath);
      paths.push(`${currentPath}[0]`);
      if (obj.length > 0 && typeof obj[0] === "object") {
        traverse(obj[0], `${currentPath}[0]`);
      }
    } else if (obj !== null && typeof obj === "object") {
      paths.push(currentPath);
      Object.keys(obj).forEach((key) => {
        const newPath =
          currentPath === "$"
            ? `${currentPath}.${key}`
            : `${currentPath}.${key}`;
        traverse(obj[key], newPath);
      });
    } else {
      paths.push(currentPath);
    }
  };

  traverse(obj, parentPath);
  return [...new Set(paths)]; // Remove duplicates
};

export const getJsonPathSuggestions = async (releaseId) => {
  try {
    const results = await getResultsFromDB(releaseId);
    if (results.length === 0) {
      throw new Error("No results found");
    }

    // Take first result's output as example
    const exampleOutput = results[0].output;
    return generateJsonPaths(exampleOutput);
  } catch (error) {
    console.error("Error generating JSONPath suggestions:", error);
    return [];
  }
};

export default BatchTestDB;
