import { call, put, takeLatest, all, fork, delay } from "redux-saga/effects";
import axios from "api/axios";
import {
  createDeployReleaseFailure,
  createDeployReleaseRequest,
  createDeployReleaseSuccess,
  deleteReleaseFailure,
  deleteReleaseRequest,
  deleteReleaseSuccess,
  getReleaseApiEndPointsFailure,
  getReleaseApiEndPointsRequest,
  getReleaseApiEndPointsSuccess,
  getReleaseFailure,
  getReleaseRequest,
  getReleasesFailure,
  getReleasesRequest,
  getReleasesSuccess,
  getReleaseSuccess,
  getTestReleaseFailure,
  getTestReleaseRequest,
  getTestReleaseSuccess,
  getWorkflowReleasesFailure,
  getWorkflowReleasesRequest,
  getWorkflowReleasesSuccess,
  updateReleaseNotesFailure,
  updateReleaseNotesRequest,
  updateReleaseNotesSuccess,
  downloadBlueprintRequest,
  downloadBlueprintSuccess,
  downloadBlueprintFailure,
  createValidationSetRequest,
  createValidationSetSuccess,
  createValidationSetFailure,
  notifyValidationSetUploadRequest,
  notifyValidationSetUploadSuccess,
  notifyValidationSetUploadFailure,
  getValidationSetsFailure,
  getValidationSetsRequest,
  getValidationSetsSuccess,
  getValidationSetFailure,
  getValidationSetRequest,
  getValidationSetSuccess,
  deleteValidationSetFailure,
  deleteValidationSetRequest,
  deleteValidationSetSuccess,
  startBatchTestRequest,
  startBatchTestSuccess,
  startBatchTestFailure,
  addBatchTestResult,
  loadBatchTestResultsRequest,
  loadBatchTestResultsSuccess,
  loadBatchTestResultsFailure,
  clearBatchTestResultsRequest,
  clearBatchTestResultsSuccess,
  clearBatchTestResultsFailure,
} from "redux/releases/action";
import {
  addResultToDB,
  getResultsFromDB,
  clearResultsFromDB,
  clearPendingTestsFromDB,
  getPendingTestsFromDB,
  markTestAsProcessed,
} from "utility/BatchTestDB";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";

function* getReleases({ payload }) {
  try {
    let url = "/releases";
    if (payload) {
      url = `releases?page=${payload}`;
    }
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleasesSuccess(response.data));
    }
  } catch (e) {
    yield put(getReleasesFailure("e.message"));
  }
}

function* getRelease({ payload }) {
  try {
    const url = `releases/${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleaseSuccess(response.data));
    }
  } catch (e) {
    if (e.response.status === 404) {
      window.location.href = "/page-not-found";
    }
    yield put(getReleaseFailure("e.message"));
  }
}

function* getWorkflowReleases({ payload }) {
  try {
    const url = `workflows/${payload}/releases`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getWorkflowReleasesSuccess(response.data));
    }
  } catch (e) {
    yield put(getWorkflowReleasesFailure("e.message"));
  }
}

function* deleteRelease({ payload }) {
  try {
    const url = `releases/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteReleaseSuccess());
    }
  } catch (e) {
    yield put(deleteReleaseFailure("e.message"));
  }
}

function* getTestRelease({ payload }) {
  try {
    const url = `releases/${payload.id}/execute?call_ext_data=${payload.callExtData}&record_steps=${payload.recordSteps}&record_lineage=${payload.recordLineage}`;
    const response = yield call(axios.post, url, payload.testingInputData);
    if (response.status === 200) {
      yield put(getTestReleaseSuccess(response.data));
    }
  } catch (e) {
    yield put(getTestReleaseFailure("e.message"));
  }
}

function* getReleaseApiEndPoints({ payload }) {
  try {
    const url = `api-endpoints?workflow_release_id=${payload}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getReleaseApiEndPointsSuccess(response.data));
    }
  } catch (e) {
    yield put(getReleaseApiEndPointsFailure("e.message"));
  }
}

function* createDeployRelease({ payload }) {
  try {
    const response = yield call(axios.post, "deployments", payload);
    if (response.status === 201) {
      yield put(createDeployReleaseSuccess());
    }
  } catch (e) {
    yield put(createDeployReleaseFailure(e.response.status));
  }
}

function* updateReleaseNotes({ payload }) {
  try {
    const url = `releases/${payload.id}`;
    const response = yield call(axios.put, url, payload.data);
    if (response.status === 200) {
      yield put(updateReleaseNotesSuccess());
    }
  } catch (e) {
    yield put(updateReleaseNotesFailure("e.message"));
  }
}

function* downloadBlueprint({ payload }) {
  try {
    const response = yield call(
      axios.get,
      `releases/${payload.id}/blueprints`,
      {
        responseType: "blob",
      }
    );
    if (response.status === 200) {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", payload.filename || "blueprint.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      yield put(downloadBlueprintSuccess());
    } else {
      yield put(downloadBlueprintFailure("Failed to download blueprint"));
    }
  } catch (e) {
    yield put(downloadBlueprintFailure(e.message));
  }
}

function* createValidationSet({ payload }) {
  try {
    const response = yield call(axios.post, "/validation-sets", payload);
    if (response.status === 201) {
      yield put(
        createValidationSetSuccess({
          data: response.data,
          eTag: response.headers.etag,
        })
      );
    }
  } catch (e) {
    yield put(createValidationSetFailure(e.message));
  }
}

function* notifyValidationSetUpload({ payload }) {
  try {
    const response = yield call(
      axios.post,
      "/validation-sets/" +
        payload.release_validation_set_id +
        "/notifications",
      payload
    );
    if (response.status === 201) {
      yield put(notifyValidationSetUploadSuccess());
    } else {
      yield put(
        notifyValidationSetUploadFailure(
          "Failed to notify validation set upload"
        )
      );
    }
  } catch (e) {
    yield put(notifyValidationSetUploadFailure(e.message));
  }
}

//get set of validation sets
function* getValidationSets({ payload }) {
  try {
    const response = yield call(axios.get, "/validation-sets");
    if (response.status === 200) {
      yield put(getValidationSetsSuccess(response.data));
    }
  } catch (e) {
    yield put(getValidationSetsFailure(e.message));
  }
}

//get validation set
function* getValidationSet({ payload }) {
  try {
    const response = yield call(
      axios.get,
      "/validation-sets/" + payload.validationSetId
    );
    if (response.status === 200) {
      yield put(getValidationSetSuccess(response.data));
    } else {
      yield put(getValidationSetFailure("Failed to get validation set"));
    }
  } catch (e) {
    yield put(getValidationSetFailure(e.message));
  }
}

//delete validation set
function* deleteValidationSet({ payload }) {
  try {
    const response = yield call(
      axios.delete,
      "/validation-sets/" + payload.validationSetId
    );
    if (response.status === 204) {
      yield put(deleteValidationSetSuccess());
    } else {
      yield put(deleteValidationSetFailure("Failed to delete validation set"));
    }
  } catch (e) {
    yield put(deleteValidationSetFailure(e.message));
  }
}
// Worker Saga: Handle individual test
function* handleIndividualTest(test, releaseId) {
  try {
    const url = `releases/${releaseId}/execute`;
    const response = yield call(axios.post, url, test.input);
    if (response.status === 200) {
      const result = {
        input: test.input,
        output: response.data,
        status: "success",
      };
      yield put(addBatchTestResult(result));
      yield call(addResultToDB, releaseId, result);
    } else {
      const result = {
        input: test.input,
        output: response.data,
        status: "failure",
      };
      yield put(addBatchTestResult(result));
      yield call(addResultToDB, releaseId, result);
    }
    // Mark the test as processed
    yield call(markTestAsProcessed, test.id);
  } catch (error) {
    const result = {
      input: test.input,
      output: error.message,
      status: "failure",
    };
    yield put(addBatchTestResult(result));
    yield call(addResultToDB, releaseId, result);
    // Optionally mark as processed or handle retry logic
    yield call(markTestAsProcessed, test.id);
  }
}

// Saga: Start Batch Test
function* startBatchTestSaga({ payload }) {
  try {
    const totalCount = payload.totalCount;
    let offset = 0;
    const batchSize = 100;

    while (true) {
      // Get next batch of tests
      const pendingTests = yield call(
        getPendingTestsFromDB,
        payload.releaseId,
        offset,
        batchSize
      );

      if (!pendingTests.length) {
        break; // No more tests to process
      }

      // Process current batch with concurrency
      const concurrency = 5;
      for (let i = 0; i < pendingTests.length; i += concurrency) {
        const batch = pendingTests.slice(i, i + concurrency);

        yield all(
          batch.map((test) =>
            fork(handleIndividualTest, test, payload.releaseId)
          )
        );

        // Update progress
        offset += batch.length;
        yield put({
          type: "UPDATE_BATCH_PROGRESS",
          payload: {
            completed: Math.min(offset, totalCount),
            total: totalCount,
          },
        });

        yield delay(100); // Prevent server overload
      }
    }

    yield put(startBatchTestSuccess());
  } catch (error) {
    yield put(startBatchTestFailure(error.message));
  }
}

// Saga: Load Batch Test Results from IndexedDB
function* loadBatchTestResultsSaga({ payload }) {
  const { releaseId } = payload;
  try {
    const results = yield call(getResultsFromDB, releaseId);
    yield put(loadBatchTestResultsSuccess(results));
  } catch (error) {
    yield put(loadBatchTestResultsFailure(error.message));
    toast.error("Failed to load batch test results.");
  }
}

// Worker Saga: Handle Clearing Batch Test Results
function* clearBatchTestResultsSaga({ payload }) {
  const { releaseId } = payload;
  try {
    yield call(clearResultsFromDB, releaseId);
    yield call(clearPendingTestsFromDB, releaseId);

    // Dispatch success action
    yield put(clearBatchTestResultsSuccess());
  } catch (error) {
    // Dispatch failure action with error message
    yield put(clearBatchTestResultsFailure(error.message));
  }
}

// Watcher Sagas
function* watchStartBatchTest() {
  yield takeLatest(startBatchTestRequest, startBatchTestSaga);
}

function* watchLoadBatchTestResults() {
  yield takeLatest(loadBatchTestResultsRequest, loadBatchTestResultsSaga);
}

function* watchClearBatchTestResults() {
  yield takeLatest(clearBatchTestResultsRequest, clearBatchTestResultsSaga);
}

export default function* saga() {
  yield takeLatest(getReleasesRequest, getReleases);
  yield takeLatest(getReleaseRequest, getRelease);
  yield takeLatest(getWorkflowReleasesRequest, getWorkflowReleases);
  yield takeLatest(deleteReleaseRequest, deleteRelease);
  yield takeLatest(getTestReleaseRequest, getTestRelease);
  yield takeLatest(getReleaseApiEndPointsRequest, getReleaseApiEndPoints);
  yield takeLatest(createDeployReleaseRequest, createDeployRelease);
  yield takeLatest(updateReleaseNotesRequest, updateReleaseNotes);
  yield takeLatest(downloadBlueprintRequest, downloadBlueprint);
  yield takeLatest(createValidationSetRequest, createValidationSet);
  yield takeLatest(notifyValidationSetUploadRequest, notifyValidationSetUpload);
  yield takeLatest(getValidationSetsRequest, getValidationSets);
  yield takeLatest(getValidationSetRequest, getValidationSet);
  yield takeLatest(deleteValidationSetRequest, deleteValidationSet);
  // Batch Testing Watchers
  yield all([
    fork(watchStartBatchTest),
    fork(watchLoadBatchTestResults),
    fork(watchClearBatchTestResults),
  ]);
}
