import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Spinner, Alert } from "react-bootstrap";
import { JSONPath } from "jsonpath-plus";
import ReactECharts from "echarts-for-react";
import { getResultsFromDB, getJsonPathSuggestions } from "utility/BatchTestDB";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import AnalysisRecipeForm from "./AnalysisRecipeForm"; // Ensure correct path
import "./AnalyticsModal.css";
import { useTranslation } from "react-i18next";

const AnalyticsModal = ({
  show,
  handleClose,
  releaseId,
  decisionFlowTitle,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    slices: [],
  });
  const [chartOptions, setChartOptions] = useState([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState(null);
  const [jsonPathSuggestions, setJsonPathSuggestions] = useState([]);

  const handleAnalyze = async () => {
    setIsAnalyzing(true);
    setChartOptions([]);
    setError(null);
    try {
      // Ensure slices are not empty - we need at least metric and aggregation
      if (formData.slices.length === 0) {
        toast.warn(t("please_add_at_least_one_slice"), ToastOptions);
        setIsAnalyzing(false);
        return;
      }
      //check that metric json path is not empty
      for (const slice of formData.slices) {
        if (!slice.metric) {
          toast.warn(t("please_add_a_metric_json_path"), ToastOptions);
          setIsAnalyzing(false);
          return;
        }

        //check that metric json path is valid and exists in the json path suggestions
        if (!jsonPathSuggestions.includes(slice.metric)) {
          toast.warn(t("please_add_at_least_one_metric"), ToastOptions);
          setIsAnalyzing(false);
          return;
        }
        //check that dimension json path is valid and exists in the json path suggestions
        if (slice.dimension && !jsonPathSuggestions.includes(slice.dimension)) {
          toast.warn(t("please_add_a_valid_dimension_json_path"), ToastOptions);
          setIsAnalyzing(false);
          return;
        }
      }

      const results = await getResultsFromDB(releaseId);

      if (results.length === 0) {
        toast.error(t("no_batch_test_results_found"), ToastOptions);
        setIsAnalyzing(false);
        return;
      }

      const aggregatedCharts = [];

      for (const slice of formData.slices) {
        console.log(slice);
        const { metric: path, dimension, aggregation, name } = slice;

        // Extract data using JSONPath
        const extractedData = results
          .map((result) => {
            try {
              // Correct JSONPath usage
              const value = JSONPath({
                path: path,
                json: result.output,
                wrap: false,
              });
              return value;
            } catch (error) {
              return null;
            }
          })
          .filter((item) => item !== null && item !== undefined);

        if (extractedData.length === 0) {
          toast.warn(
            t("no_data_found_for_jsonpath", { jsonPath: path }),
            ToastOptions
          );
          continue;
        }

        let aggregatedResult = {};

        if (dimension) {
          // Extract dimension values
          const dimensionData = results
            .map((result) => {
              try {
                return JSONPath({
                  path: dimension,
                  json: result.output,
                  wrap: false,
                });
              } catch (error) {
                return null;
              }
            })
            .filter((item) => item !== null && item !== undefined);

          // Group data by dimension
          const groupedData = {};
          for (let i = 0; i < extractedData.length; i++) {
            const dim = dimensionData[i];
            const value = extractedData[i];
            if (!groupedData[dim]) groupedData[dim] = [];
            groupedData[dim].push(Number(value));
          }

          // Perform aggregation per group
          for (const [dim, values] of Object.entries(groupedData)) {
            aggregatedResult[dim] = aggregateValues(values, aggregation);
          }
        } else {
          // Global aggregation
          aggregatedResult["Global"] = aggregateValues(
            extractedData,
            aggregation
          );
        }

        // Prepare ECharts option based on aggregation
        const colorPalette = [
          "#003057", // Primary blue
          "#005C42", // Secondary green
          "#455A64", // Dark gray
          "#8FD4F2", // Light blue
          "#014A80", // Secondary blue
          "#0F3356", // Navy blue
          "#6C757D", // Gray
          "#F8F9FA", // Off-white
          "#DEE2E6", // Light gray
        ];

        let option = {};
        if (aggregation === "count") {
          const categories = Object.keys(aggregatedResult);
          const data = Object.values(aggregatedResult);
          option = {
            color: colorPalette,
            title: {
              text: `${formData.title || t("analytics")} - ${t(
                "count"
              )} ${name}`,
              left: "center",
            },
            tooltip: {},
            xAxis: {
              type: "category",
              data: categories,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: data,
                type: "bar",
              },
            ],
          };
        } else if (aggregation === "deciles") {
          const categories = Array.from({ length: 9 }, (_, i) => `D${i + 1}`);
          const dimensions = Object.keys(aggregatedResult);
          const series = dimensions.map((dim, index) => ({
            name: dim,
            type: "line",
            smooth: true,
            data: aggregatedResult[dim],
            areaStyle: {
              opacity: 0.1,
            },
            markPoint: {
              data: [
                { type: "max", name: "Maximum" },
                { type: "min", name: "Minimum" },
              ],
            },
          }));

          option = {
            color: colorPalette,
            title: {
              text: `${formData.title || t("analytics")} - ${t(
                "deciles_by_dimension"
              )}: ${name}`,
              left: "center",
            },
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params
                  .map(
                    (param) => `${param.seriesName}: ${param.value.toFixed(2)}`
                  )
                  .join("<br/>");
              },
            },
            legend: {
              data: dimensions,
              top: 30,
            },
            xAxis: {
              type: "category",
              data: categories,
              name: t("decile"),
            },
            yAxis: {
              type: "value",
              name: "Value",
            },
            series: series,
          };
        } else if (aggregation === "quartiles") {
          const categories = ["Q1", "Q2", "Q3"];
          const dimensions = Object.keys(aggregatedResult);
          const series = dimensions.map((dim) => ({
            name: dim,
            type: "bar",
            data: aggregatedResult[dim],
            markLine: {
              data: [{ type: "average", name: "Average" }],
            },
          }));

          option = {
            color: colorPalette,
            title: {
              text: `${
                formData.title || t("analytics")
              } - Quartiles by Dimension: ${name}`,
              left: "center",
            },
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return params
                  .map(
                    (param) => `${param.seriesName}: ${param.value.toFixed(2)}`
                  )
                  .join("<br/>");
              },
            },
            legend: {
              data: dimensions,
              top: 30,
            },
            xAxis: {
              type: "category",
              data: categories,
              name: "Quartile",
            },
            yAxis: {
              type: "value",
              name: "Value",
            },
            series: series,
          };
        } else {
          const categories = Object.keys(aggregatedResult);
          const data = Object.values(aggregatedResult);
          option = {
            color: colorPalette,
            title: {
              text: `${formData.title || t("analytics")} - ${capitalize(
                aggregation
              )}: ${name}`,
              left: "center",
            },
            tooltip: {},
            xAxis: {
              type: "category",
              data: categories,
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                data: data,
                type: "bar",
              },
            ],
          };
        }

        aggregatedCharts.push(option);
      }

      if (aggregatedCharts.length === 0) {
        toast.warn(t("no_valid_aggregations_performed"), ToastOptions);
      } else {
        setChartOptions(aggregatedCharts);
        toast.success(t("analysis_completed_successfully"), ToastOptions);
      }
    } catch (err) {
      toast.error(t("error_occured_during_analysis"), ToastOptions);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const calculateDeciles = (values) => {
    const sorted = [...values].sort((a, b) => a - b);
    const deciles = [];
    for (let i = 1; i <= 9; i++) {
      const index = Math.floor((i / 10) * sorted.length);
      deciles.push(sorted[index]);
    }
    return deciles;
  };

  const calculateQuartiles = (values) => {
    const sorted = [...values].sort((a, b) => a - b);
    const q1Index = Math.floor(sorted.length * 0.25);
    const q2Index = Math.floor(sorted.length * 0.5);
    const q3Index = Math.floor(sorted.length * 0.75);
    return [sorted[q1Index], sorted[q2Index], sorted[q3Index]];
  };

  useEffect(() => {
    const loadSuggestions = async () => {
      if (show && releaseId) {
        const suggestions = await getJsonPathSuggestions(releaseId);
        setJsonPathSuggestions(suggestions);
      }
    };
    loadSuggestions();
  }, [show, releaseId]);

  const aggregateValues = (values, aggregation) => {
    switch (aggregation) {
      case "count":
        return values.length;
      case "sum":
        return values.reduce((acc, val) => acc + val, 0);
      case "average":
        return values.reduce((acc, val) => acc + val, 0) / values.length;
      case "min":
        return Math.min(...values);
      case "max":
        return Math.max(...values);
      case "deciles":
        return calculateDeciles(values.map(Number));
      case "quartiles":
        return calculateQuartiles(values.map(Number));
      default:
        return null;
    }
  };

  const capitalize = (str) => {
    if (typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header>
        <h5 className="modal-title">{t("analyse_results")}</h5>
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <AnalysisRecipeForm
          formData={formData}
          setFormData={setFormData}
          onSubmit={handleAnalyze} // Pass handleAnalyze as onSubmit
          vectorsData={jsonPathSuggestions}
          decisionFlowTitle={decisionFlowTitle}
        />
        <div className="d-flex justify-content-center my-3">
          <Button
            variant=""
            type="submit"
            className="btn primary deploy-btn w-auto"
            onClick={handleAnalyze}
            disabled={isAnalyzing || formData.slices.length === 0}
          >
            {isAnalyzing ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{" "}
                {t("analyzing")}
              </>
            ) : (
              t("analyze")
            )}
          </Button>
        </div>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="analytics-results">
          {chartOptions.length > 0
            ? chartOptions.map((option, idx) => (
                <ReactECharts
                  key={idx}
                  option={option}
                  style={{ height: "400px", width: "100%", marginTop: "20px" }}
                />
              ))
            : !isAnalyzing && <p>{t("no_results")}</p>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="" className="outline deploy-btn" onClick={handleClose}>
          {t("close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AnalyticsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  releaseId: PropTypes.string.isRequired,
  decisionFlowTitle: PropTypes.string,
};

export default AnalyticsModal;
