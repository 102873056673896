import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearBatchTestResultsRequest } from "redux/releases/action";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import usePrevious from "utility/hooks/usePrevious";
import { ReactComponent as DownloadIcon } from "assets/icons/export.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import ComparisonModal from "components/modals/release/ComparisonModal";
import _ from "lodash";
import DeleteConfirm from "components/modals/DeleteConfirm";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { getPaginatedResultsFromDB } from "../../../utility/BatchTestDB";
import VirtualRow from "./VirtualRow";
import "./BatchTestResults.css";
import AnalyticsModal from "components/modals/release/AnalyticsModal/AnalyticsModal"; // Import the AnalyticsModal

const BatchTestResults = ({ releaseId, decisionFlowTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    batchTest,
    isClearingBatchTestResults,
    isClearingBatchTestResultsSuccess,
    isClearingBatchTestResultsError,
    isBatchTestSuccess,
  } = useSelector((state) => state.releases);
  const [selectedResult, setSelectedResult] = useState(null);

  // State to control the Delta Comparison Modal
  const [showComparisonModal, setShowComparisonModal] = useState(false);
  const [currentRequestData, setCurrentRequestData] = useState({});
  const [currentResponseData, setCurrentResponseData] = useState({});
  const [showClearConfirmModal, setShowClearConfirmModal] = useState(false);

  // State to control the Analytics Modal
  const [showAnalyticsModal, setShowAnalyticsModal] = useState(false);

  const prevIsClearingBatchTestResultsSuccess = usePrevious(
    isClearingBatchTestResultsSuccess
  );

  const prevIsClearingBatchTestResultsError = usePrevious(
    isClearingBatchTestResultsError
  );

  const prevIsBatchTestSuccess = usePrevious(isBatchTestSuccess);

  // Function to handle download
  const handleDownload = () => {
    if (batchTest.results.length === 0) {
      toast.error(t("no_results_to_download"), ToastOptions);
      return;
    }

    // Convert results to JSON Lines format
    const jsonLines = batchTest.results
      .map((result) => JSON.stringify(result.output))
      .join("\n");

    // Create a Blob from the JSON Lines string
    const blob = new Blob([jsonLines], { type: "application/json" });

    // Create a temporary anchor element to trigger the download
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;

    // Define the filename, e.g., "batch_test_results_2025-01-24.jsonl"
    const filename = `batch_test_results_${
      new Date().toISOString().split("T")[0]
    }.jsonl`;
    link.setAttribute("download", filename);

    // Append the link to the body, trigger click, and remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL after download
    URL.revokeObjectURL(url);

    // Optionally, show a success toast
    toast.success(t("download_started"), ToastOptions);
  };

  // Function to handle clearing results
  const handleClearResults = () => {
    setShowClearConfirmModal(true);
  };

  const handleConfirmClear = () => {
    dispatch(clearBatchTestResultsRequest({ releaseId: releaseId }));
    setShowClearConfirmModal(false);
  };

  const handleViewComparison = useCallback((result) => {
    setSelectedResult(result);
    setCurrentRequestData(result.input);
    setCurrentResponseData(result.output);
    setShowComparisonModal(true);
  }, []);

  useEffect(() => {
    if (
      prevIsClearingBatchTestResultsSuccess === false &&
      isClearingBatchTestResultsSuccess
    ) {
      toast.success(t("batch_test_results_cleared"), ToastOptions);
    }
  }, [isClearingBatchTestResultsSuccess]);

  useEffect(() => {
    if (
      prevIsClearingBatchTestResultsError === false &&
      isClearingBatchTestResultsError
    ) {
      toast.error(t("error_clearing_batch_test_results"), ToastOptions);
    }
  }, [isClearingBatchTestResultsError]);

  useEffect(() => {
    if (prevIsBatchTestSuccess === false && isBatchTestSuccess === true) {
      loadResults();
    }
  }, [isBatchTestSuccess, prevIsBatchTestSuccess]);

  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const PAGE_SIZE = 50;

  const loadResults = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      console.log("Loading results for releaseId:", releaseId, "page:", page);
      const { results: newResults, totalCount } =
        await getPaginatedResultsFromDB(releaseId, page, PAGE_SIZE);
      console.log("Loaded results:", newResults.length, "total:", totalCount);

      setResults((prev) => [...prev, ...newResults]);
      setTotalCount(totalCount);
      setPage((p) => p + 1);
    } catch (error) {
      toast.error("Error loading results.", ToastOptions);
    } finally {
      setLoading(false);
    }
  }, [releaseId, page, loading]);

  useEffect(() => {
    loadResults();
  }, [releaseId]);

  const onScroll = useCallback(
    ({ scrollOffset }) => {
      if (
        scrollOffset > results.length * 50 - 1000 &&
        results.length < totalCount
      ) {
        loadResults();
      }
    },
    [results.length, totalCount]
  );

  const getItemData = useCallback(
    () => ({
      results,
      t,
      handleViewComparison, // Pass the handler
      setSelectedResult,
    }),
    [results, t, handleViewComparison]
  );

  if (batchTest.results.length === 0) return null;

  return (
    <>
      <div className="batch-test-results mt-4">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h4>{t("batch_testing_results")}</h4>
          <div className="d-flex">
            <button
              className="btn outline"
              onClick={handleDownload}
              disabled={batchTest.results.length === 0}
            >
              <DownloadIcon /> {t("download_results")}
            </button>
            <button
              className="btn outline option-delete d-flex align-items-center ml-2"
              onClick={handleClearResults}
              disabled={
                isClearingBatchTestResults || batchTest.results.length === 0
              }
              data-tip
              data-for="clear-results-tooltip"
            >
              {isClearingBatchTestResults ? (
                t("clearing")
              ) : (
                <>
                  <TrashIcon className="pr-2" /> {t("clear_results")}
                </>
              )}
            </button>
            <button
              className="btn outline ml-2"
              onClick={() => setShowAnalyticsModal(true)}
              disabled={batchTest.results.length === 0}
            >
              Analyze Results
            </button>
            {isClearingBatchTestResults && (
              <ReactTooltip
                id="clear-results-tooltip"
                effect="solid"
                place="top"
              >
                {t("please_wait")}
              </ReactTooltip>
            )}
          </div>
        </div>

        {/* Summary */}
        <div className="mb-3">
          <strong>{t("total")}:</strong> {batchTest.total}
        </div>

        {/* Results Table */}
        <div className="virtual-table-container" style={{ height: "600px" }}>
          <div className="table-header">
            <div className="table-col col-number">#</div>
            <div className="table-col col-status">{t("status")}</div>
            <div className="table-col col-content">{t("input")}</div>
            <div className="table-col col-content">{t("output")}</div>
            <div className="table-col col-actions">{t("compare")}</div>
          </div>

          {loading && results.length === 0 ? (
            <div className="loading-indicator">Loading...</div>
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height - 40}
                  itemCount={totalCount}
                  itemSize={50}
                  width={width}
                  onScroll={onScroll}
                  itemData={getItemData()} // Use memoized data
                >
                  {VirtualRow}
                </List>
              )}
            </AutoSizer>
          )}
        </div>

        {/* Delta Comparison Modal */}
        {showComparisonModal && selectedResult && (
          <ComparisonModal
            show={showComparisonModal}
            handleClose={() => {
              setShowComparisonModal(false);
              setSelectedResult(null);
            }}
            originalData={currentRequestData}
            resultData={currentResponseData}
            title={`Comparison #${selectedResult.id || ""}`}
          />
        )}

        {/* Analytics Modal */}
        <AnalyticsModal
          show={showAnalyticsModal}
          handleClose={() => setShowAnalyticsModal(false)}
          decisionFlowTitle={decisionFlowTitle}
          releaseId={releaseId}
        />
      </div>
      <DeleteConfirm
        open={showClearConfirmModal}
        handleClose={() => setShowClearConfirmModal(false)}
        handleConfirm={handleConfirmClear}
        message={t("do_you_want_to_clear_results")}
        title={t("clear_results")}
        confirm_button={t("clear_results")}
      />
    </>
  );
};

BatchTestResults.propTypes = {
  releaseId: PropTypes.string.isRequired,
  decisionFlowTitle: PropTypes.string,
};

export default BatchTestResults;
