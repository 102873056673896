// src/components/BatchTestUploader.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startBatchTestRequest } from "redux/releases/action";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  addPendingTestToDB,
  clearPendingTestsFromDB,
  countPendingTests,
} from "utility/BatchTestDB";
import usePrevious from "utility/hooks/usePrevious";
import { chunk } from "lodash";

import { ToastOptions } from "components/toastify";

const BatchTestUploader = ({ releaseId }) => {
  const { isBatchTestSuccess, isBatchTestError } = useSelector(
    (state) => state.releases
  );

  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pendingTestsCount, setPendingTestsCount] = useState(0);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const prevIsBatchTestSuccess = usePrevious(isBatchTestSuccess);
  const prevIsBatchTestError = usePrevious(isBatchTestError);

  const processLine = (line, index) => {
    try {
      return JSON.parse(line);
    } catch (err) {
      toast.error(
        t("invalid_json_on_line_x", { line: index + 1 }),
        ToastOptions
      );
      return null;
    }
  };

  const handleUpload = async () => {
    if (!file) {
      toast.error("Please select a file to upload.", ToastOptions);
      return;
    }

    setIsProcessing(true);

    try {
      // Read file content
      const content = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.readAsText(file);
      });

      // Process all lines and store valid ones in DB
      const lines = content.split("\n").filter((line) => line.trim() !== "");
      const validTests = lines
        .map((line, index) => processLine(line, index))
        .filter((item) => item !== null);

      const totalTests = validTests.length;

      if (totalTests === 0) {
        toast.error(t("no_valid_tests_found"), ToastOptions);
        setIsProcessing(false);
        return;
      } else {
        await clearPendingTestsFromDB(releaseId);

        await addPendingTestToDB(releaseId, validTests);

        toast.success(
          t("successfully_queued_for_processing", { totalTests }),
          ToastOptions
        );

        // Dispatch batch test request
        dispatch(startBatchTestRequest({ releaseId, totalCount: totalTests }));
      }
    } catch (error) {
      toast.error(t("error_processing_file"), ToastOptions);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    if (prevIsBatchTestSuccess === false && isBatchTestSuccess) {
      toast.success(t("batch_test_completed_successfully"), ToastOptions);
    }
  }, [isBatchTestSuccess, prevIsBatchTestSuccess]);

  useEffect(() => {
    if (prevIsBatchTestError === false && isBatchTestError) {
      toast.error(t("batch_test_failed"), ToastOptions);
    }
  }, [isBatchTestError, prevIsBatchTestError]);

  return (
    <div className="batch-test-uploader">
      <input
        type="file"
        accept=".json,.jsonl,.txt"
        onChange={(e) => setFile(e.target.files[0])}
        disabled={isProcessing}
      />
      <button
        onClick={handleUpload}
        className="btn primary mt-2"
        disabled={isProcessing || !file}
      >
        {isProcessing ? t("processing") : t("start_batch_test")}
      </button>
    </div>
  );
};

BatchTestUploader.propTypes = {
  releaseId: PropTypes.string.isRequired,
};

export default BatchTestUploader;
