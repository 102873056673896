import { call, put, select, takeLatest } from "redux-saga/effects";
import axios from "api/axios";
import {
  createRuleSetFailure,
  createRuleSetRequest,
  createRuleSetSuccess,
  deleteRuleActionFailure,
  deleteRuleActionRequest,
  deleteRuleActionSuccess,
  deleteRuleConditionFailure,
  deleteRuleConditionRequest,
  deleteRuleConditionSuccess,
  deleteRuleFailure,
  deleteRuleRequest,
  deleteRuleSetFailure,
  deleteRuleSetRequest,
  deleteRuleSetSuccess,
  deleteRuleSuccess,
  getConditionTypesFailure,
  getConditionTypesRequest,
  getConditionTypesSuccess,
  getRuleRevisionFailure,
  getRuleRevisionRequest,
  getRuleRevisionsFailure,
  getRuleRevisionsRequest,
  getRuleRevisionsSuccess,
  getRuleRevisionSuccess,
  getRuleRevision2Failure,
  getRuleRevision2Request,
  getRuleRevision2Success,
  getRuleSetFailure,
  getRuleSetForCopySuccess,
  getRuleSetRequest,
  getRuleSetsFailure,
  getRuleSetsRequest,
  getRuleSetsSuccess,
  getRuleSetSuccess,
  getRunTestResultDetailsFailure,
  getRunTestResultDetailsRequest,
  getRunTestResultDetailsSuccess,
  getRunTestResultStatusFailure,
  getRunTestResultStatusRequest,
  getRunTestResultStatusSuccess,
  getTestRuleSetFailure,
  getTestRuleSetRequest,
  getTestRuleSetSuccess,
  updateRuleSetFailure,
  updateRuleSetRequest,
  updateRuleSetSuccess,
  getRuleExplanationRequest,
  getRuleExplanationSuccess,
  getRuleExplanationFailure,
  getRuleSetChatFailure,
  getRuleSetChatRequest,
  getRuleSetChatSuccess,
} from "redux/rulesets/action";

import { useCaseWorkflowRequest } from "../workflows/action";

const useCaseWorkflowSelector = (state) => state.workflows.useCaseWorkflow;

function* getRuleSets({ payload }) {
  try {
    let url = "/rule-sets";
    if (payload) {
      url = `rule-sets?page=${payload}`;
    }
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getRuleSetsSuccess(response.data));
    }
  } catch (e) {
    yield put(getRuleSetsFailure("e.message"));
  }
}

function* getRuleSet({ payload }) {
  try {
    const url = `/rule-sets/${payload.id}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      if (!payload.importMode) {
        yield put(getRuleSetSuccess(response.data));
      } else {
        yield put(getRuleSetForCopySuccess(response.data));
      }
    }
  } catch (e) {
    if (e.response.status === 404 && !payload?.copyMode) {
      window.location.href = "/page-not-found";
    }
    yield put(getRuleSetFailure("e.message"));
  }
}

function* deleteRuleSet({ payload }) {
  try {
    const url = `/rule-sets/${payload}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteRuleSetSuccess(response.data));
    }
  } catch (e) {
    yield put(deleteRuleSetFailure("e.message"));
  }
}

function* getConditionTypes({ payload }) {
  try {
    const url = `/rule-sets/${payload}/condition-types`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getConditionTypesSuccess(response.data));
    }
  } catch (e) {
    yield put(getConditionTypesFailure("e.message"));
  }
}

function* createRuleSet({ payload }) {
  try {
    let finallyData;
    if (payload.ruleJsonData) {
      finallyData = payload.ruleJsonData;
    } else {
      finallyData = payload;
    }
    const response = yield call(axios.post, "/rule-sets", finallyData);
    if (response.status === 201) {
      yield put(createRuleSetSuccess(response.data));
      if (payload.rule_set_id) {
        const workflow = yield select(useCaseWorkflowSelector);
        workflow.workflow_step.map((step) => {
          step.options?.map((option) => {
            if (option.rule_set_id === payload.rule_set_id) {
              option.rule_set_id = response.headers.etag;
            }
          });
        });
        yield put(useCaseWorkflowRequest(workflow));
      }
    }
  } catch (e) {
    yield put(createRuleSetFailure(e.message));
  }
}

function* updateRuleSet({ payload }) {
  try {
    const url = `/rule-sets/${payload.id}`;
    const response = yield call(axios.put, url, payload.ruleJsonData);
    if (response.status === 200) {
      yield put(updateRuleSetSuccess(response.data));
    }
  } catch (e) {
    yield put(updateRuleSetFailure(e.message));
  }
}

function* deleteRule({ payload }) {
  try {
    const url = `/rule-sets/${payload[0]}/rules/${payload[1]}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteRuleSuccess(response.data));
    }
  } catch (e) {
    yield put(deleteRuleFailure(e.message));
  }
}

function* deleteRuleCondition({ payload }) {
  try {
    const url = `/rule-sets/${payload[0]}/conditions/${payload[1]}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteRuleConditionSuccess());
    }
  } catch (e) {
    yield put(deleteRuleConditionFailure(e.message));
  }
}

function* deleteRuleAction({ payload }) {
  try {
    const url = `/rule-sets/${payload[0]}/actions/${payload[1]}`;
    const response = yield call(axios.delete, url);
    if (response.status === 204) {
      yield put(deleteRuleActionSuccess());
    }
  } catch (e) {
    yield put(deleteRuleActionFailure(e.message));
  }
}

function* getTestRuleSet({ payload }) {
  try {
    const url = `/rule-sets/${payload}/test`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getTestRuleSetSuccess(response.data));
    }
  } catch (e) {
    yield put(getTestRuleSetFailure(e.message));
  }
}

function* getRunTestResultDetails({ payload }) {
  try {
    const url = `/rule-sets/${payload.id}/test`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultDetailsSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultDetailsFailure(e.message));
  }
}

function* getRunTestResultStatus({ payload }) {
  try {
    const url = `/rule-sets/${payload.id}/test-object/${payload.vectorId}`;
    const response = yield call(axios.post, url, payload.data);
    if (response.status === 200) {
      yield put(getRunTestResultStatusSuccess(response.data));
    }
  } catch (e) {
    yield put(getRunTestResultStatusFailure(e.message));
  }
}

function* getRuleRevisions({ payload }) {
  try {
    const url = `/rule-sets/${payload}/revisions`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getRuleRevisionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getRuleRevisionsFailure(e.message));
  }
}

function* getRevision({ payload }) {
  try {
    const url = `/rule-sets/${payload[0]}/revisions/${payload[1]}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getRuleRevisionSuccess(response.data));
    }
  } catch (e) {
    yield put(getRuleRevisionFailure(e.message));
  }
}

//revision 2
function* getRevision2({ payload }) {
  try {
    const url = `/rule-sets/${payload[0]}/revisions/${payload[1]}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getRuleRevision2Success(response.data));
    }
  } catch (e) {
    yield put(getRuleRevision2Failure(e.message));
  }
}

//explanation
function* getRuleExplanation({ payload }) {
  try {
    const url = `/rule-sets/${payload.rule_set_id}/rule-explain/${payload.rule_id}`;
    const response = yield call(axios.get, url);
    if (response.status === 200) {
      yield put(getRuleExplanationSuccess(response.data));
    }
  } catch (e) {
    yield put(getRuleExplanationFailure(e.message));
  }
}

//chat
function* getRuleSetChat({ payload }) {
  //we will post as payload the rule set id, and the message

  try {
    const url = `/rule-sets/${payload.rule_set_id}/chat`;
    const response = yield call(axios.post, url, payload);
    if (response.status === 200) {
      yield put(getRuleSetChatSuccess(response.data));
    }
  } catch (e) {
    yield put(getRuleSetChatFailure(e.message));
  }
}

export default function* saga() {
  yield takeLatest(getRuleSetsRequest, getRuleSets);
  yield takeLatest(getRuleSetRequest, getRuleSet);
  yield takeLatest(createRuleSetRequest, createRuleSet);
  yield takeLatest(updateRuleSetRequest, updateRuleSet);
  yield takeLatest(deleteRuleSetRequest, deleteRuleSet);
  yield takeLatest(deleteRuleRequest, deleteRule);
  yield takeLatest(deleteRuleConditionRequest, deleteRuleCondition);
  yield takeLatest(deleteRuleActionRequest, deleteRuleAction);
  yield takeLatest(getTestRuleSetRequest, getTestRuleSet);
  yield takeLatest(getRunTestResultDetailsRequest, getRunTestResultDetails);
  yield takeLatest(getRunTestResultStatusRequest, getRunTestResultStatus);
  yield takeLatest(getRuleRevisionsRequest, getRuleRevisions);
  yield takeLatest(getRuleRevisionRequest, getRevision);
  yield takeLatest(getRuleRevision2Request, getRevision2);
  yield takeLatest(getConditionTypesRequest, getConditionTypes);
  yield takeLatest(getRuleExplanationRequest, getRuleExplanation);
  yield takeLatest(getRuleSetChatRequest, getRuleSetChat);
}
