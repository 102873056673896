import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { FormulaContext, MainContext, RevisionContext } from "context/contexts";

import ReactTooltip from "react-tooltip";
import {
  contextMenu,
  defaultFceItems,
  defaultFormulaItem,
  defaultMathematicalFunction,
  generateCloudInstanceName,
  getVectors,
  inputAdder,
  newAttribute,
  operators,
} from "utility/utility";
import DeleteConfirm from "components/modals/DeleteConfirm";
import Attribute from "components/functions/content/items/Attribute";
import Brackets from "components/functions/content/items/Brackets";
import Formula from "components/modals/function/Formula";
import { ReactComponent as FuncIcon } from "assets/icons/function.svg";
import { renderFormula } from "../utils";
import DraggableFormula from "../sortableItems/DraggableFormula";
import { ContextMenu, MenuItem, SubMenu } from "react-contextmenu";
import "assets/css/react-contextmenu.css";
import { useTranslation } from "react-i18next";
import "katex/dist/katex.min.css";
import { InlineMath } from "react-katex";
import { convertToLatex } from "../convertToLatex";

const FunctionContent = ({
  info,
  items,
  attributes,
  setAttributes,
  visualizeFormula = false,
  setVisualizeFormula = () => {},
  hideFields = false, // New prop
}) => {
  const { autoSuggestData, vectorsData, setIsEdited } = useContext(MainContext);
  const revisionMode = useContext(RevisionContext);
  const { id } = useParams();
  const { t } = useTranslation();

  const [attrId, setAttrId] = useState("");
  const [itemsData, setItemsData] = useState(defaultFormulaItem);
  const [infoData, setInfoData] = useState(items);
  const [xAttributePath, setXAttributePath] = useState(
    infoData?.x_attribute_path || ""
  );
  const [visualizedFormula, setVisualizedFormula] = useState("");
  const [isDeleteAttributeModalOpen, setIsDeleteAttributeModalOpen] =
    useState(false);
  const [vectorsDataClone, setVectorsDataClone] = useState([]);
  const [isDraggable, setIsDraggable] = useState(false);
  const [contextMenuItem, setContextMenuItem] = useState({});
  const [inputValues, setInputValues] = useState({
    title: "",
    name: "",
  });

  useEffect(() => {
    return () => {
      setIsEdited(false);
    };
  }, []);

  useEffect(() => {
    if (isDraggable) {
      document
        .getElementById("function-save-btn")
        ?.setAttribute("disabled", "disabled");
    } else {
      document.getElementById("function-save-btn")?.removeAttribute("disabled");
    }
  }, [isDraggable]);

  const formatFormula = (formula) => {
    if (!formula) return "";

    return convertToLatex(formula);
  };

  useEffect(() => {
    if (items.length > 0 || (items.children && items.children.length > 0)) {
      const itemsDataClone = structuredClone(defaultFormulaItem);
      if (id) {
        itemsDataClone.children = items;
        let res = renderFormula(items, "", attributes);

        setVisualizedFormula(res);
      } else {
        setXAttributePath("$.x");
        itemsDataClone.children = items.children;
      }
      setItemsData(itemsDataClone);
    }
  }, [items]);

  useEffect(() => {
    if (info) {
      setInfoData(structuredClone(info));
      setXAttributePath(info.x_attribute_path || "");
    }
  }, [info]);

  useEffect(() => {
    getVectors(autoSuggestData, setVectorsDataClone, true);
  }, [autoSuggestData]);

  useEffect(() => {
    setInputValues({
      title: infoData?.title || "",
      name:
        "name" in infoData
          ? infoData.name
          : !id
          ? generateCloudInstanceName()
          : "",
    });
  }, [infoData, id]);

  const addAttribute = () => {
    setIsEdited(true);
    const newAttr = { ...newAttribute };
    const attributesData = [...attributes];
    newAttr.fce_attribute_id = `new_${Math.round(Math.random() * 10000)}`;
    attributesData.push(newAttr);
    setAttributes(attributesData);
  };

  const handleDeleteAttributeModal = (id) => {
    setAttrId(id);
    setIsDeleteAttributeModalOpen(true);
  };

  const handleClose = () => {
    setAttrId("");
    setIsDeleteAttributeModalOpen(false);
  };

  const handleConfirm = () => {
    setAttributes(
      attributes.filter((attr) => attr.fce_attribute_id !== attrId)
    );
    handleClose();
  };

  const changeItemsData = (items) => {
    setIsEdited(true);
    setItemsData(structuredClone(items));
  };

  const currentInput = (
    child,
    path,
    newAddedItem,
    parent = {},
    parentItemIndex = null
  ) => {
    if (path.length === 1) {
      if (newAddedItem.operator === "delete") {
        if (child[path[0] + 1]) {
          child.splice(path[0], 2);
        } else if (child[path[0] - 1]) {
          child.splice(path[0] - 1, 2);
        }
        if (child.length === 1 && parent[parentItemIndex]?.item_type === "b") {
          parent[parentItemIndex].item_type = child[0].item_type;
          parent[parentItemIndex].operator = child[0].operator;
          parent[parentItemIndex].children = [];
        }
      } else if (newAddedItem.item_type !== "delete") {
        child[path[0]] = newAddedItem;
      } else {
        if (child.length > 1) {
          child.splice(path[0], 1);
          if (child[path[0] - 1]) {
            if (child[path[0] - 1].item_type === "o") {
              child.splice(path[0] - 1, 1);
            }
          } else if (child[path[0]].item_type === "o") {
            child.splice(0, 1);
          }
        }
      }

      return child;
    } else {
      const newPath = [...path];
      if (
        child[path[0]].item_type === "b" &&
        newAddedItem.item_type === "delete" &&
        path.length === 2
      ) {
        /**
         * Remove operator when child[path[0]].children.length > 3
         * Overwrite Brackets where less than 2
         */
        const start =
          path[1] === 0
            ? 0
            : child[path[0]].children[path[1] + 1]
            ? path[1]
            : path[1] - 1;
        const end = path[1] === 0 ? path[1] + 2 : path[1];
        child[path[0]].children.splice(start, end);

        if (child[path[0]].children.length === 1) {
          child[path[0]] = {
            ...child[path[0]].children[0],
            parent_fce_item_id: child[path[0]].parent_fce_item_id,
          };
        }

        return child;
      }

      if (
        child[path[0]].item_type === "f" &&
        newAddedItem.item_type === "delete" &&
        path.length === 2
      ) {
        /**
         * Remove operator when child[path[0]].children.length > 3
         * Overwrite Brackets where less than 2
         */

        if (child[path[0]].children.length === 1) {
          const itemIndex = child.findIndex(
            (item) => item.fce_item_id === child[path[0]].fce_item_id
          );
          child.splice(itemIndex, itemIndex + 1);
          if (child[itemIndex + 1]) {
            const nextItemIndex = child.findIndex(
              (item) => item.fce_item_id === child[itemIndex + 1].fce_item_id
            );
            child.splice(itemIndex, nextItemIndex);
          } else if (child[itemIndex - 1]) {
            const prevItemIndex = child.findIndex(
              (item) => item.fce_item_id === child[itemIndex - 1].fce_item_id
            );
            child.splice(prevItemIndex, itemIndex);
          }

          if (
            parent[parentItemIndex]?.item_type === "f" &&
            parent[parentItemIndex]?.children?.length === 0
          ) {
            parent.splice(parentItemIndex, 1);
            if (parent[parentItemIndex + 1]) {
              parent.splice(parentItemIndex + 1, 1);
            } else if (parent[parentItemIndex - 1]) {
              parent.splice(parentItemIndex - 1, 1);
            }
          } else if (
            parent[parentItemIndex]?.item_type === "b" &&
            parent[parentItemIndex]?.children?.length === 1
          ) {
            parent[parentItemIndex] = {
              ...parent[parentItemIndex].children[0],
              parent_fce_item_id: parent[parentItemIndex].parent_fce_item_id,
            };
          }
        } else {
          const start =
            path[1] === 0
              ? path[1]
              : child[path[0]].children[path[1] + 1]
              ? path[1]
              : path[1] - 1;
          const end = path[1] === 0 ? path[1] + 2 : path[1];
          child[path[0]].children.splice(start, end);
          if (child[path[0]].children[path[1] - 1]) {
            if (child[path[0]].children[path[1] - 1].item_type === "o") {
              child[path[0]].children.splice(0, path[1] - 2);
            }
          } else if (child[path[0]].children[path[1] + 1]) {
            if (child[path[0]].children[path[1] + 1].item_type === "o") {
              child[path[0]].children.splice(0, path[1] + 2);
            }
          }
        }

        return child;
      }

      const index = newPath[0];
      newPath.splice(0, 1);

      currentInput(child[index].children, newPath, newAddedItem, child, index);
    }
  };

  const addInput = (child, path, newAddedItems) => {
    setIsEdited(true);
    if (path.length === 1) {
      child[path[0]].children = [...child[path[0]].children, ...newAddedItems];
      return child;
    } else if (path.length === 0) {
      child.children = [...child.children, ...newAddedItems];
      return child;
    } else {
      const newPath = [...path];
      const index = newPath[0];
      newPath.splice(0, 1);

      addInput(child[index].children, newPath, newAddedItems);
    }
  };

  const handleTypeChange = (item, item_type, path, parentItemType = null) => {
    setIsEdited(true);
    const itemsDataClone = structuredClone(itemsData);
    let newAddedItem = {
      ...item,
      item_type: parentItemType || item_type,
    };

    if (parentItemType || item_type === "delete") {
      newAddedItem.operator = item_type;
    }

    if (item_type === "b") {
      newAddedItem.children = structuredClone(
        defaultFceItems(item.fce_item_id)
      );
    } else if (parentItemType === "f") {
      if (!newAddedItem.children.length) {
        newAddedItem.children = structuredClone(
          defaultMathematicalFunction(item.fce_item_id)
        );
      }
    }

    currentInput(itemsDataClone.children, path, newAddedItem);

    setItemsData(itemsDataClone);
  };

  const handleDeleteOperator = (path) => {
    const itemsDataClone = structuredClone(itemsData);
    setIsEdited(true);

    // Remove the operator at the current path + the next item
    removeItemAtPath(itemsDataClone.children, path);
    removeItemAtPath(itemsDataClone.children, path);

    setItemsData(itemsDataClone);
  };

  // Helper function to remove item at a specific path
  const removeItemAtPath = (children, path) => {
    if (path.length === 1) {
      children.splice(path[0], 1);
    } else {
      removeItemAtPath(children[path[0]].children, path.slice(1));
    }
  };

  const handleOperatorChange = (item, operator, path) => {
    if (operator === "Delete") {
      return handleDeleteOperator(path);
    }

    const itemsDataClone = structuredClone(itemsData);
    setIsEdited(true);
    let newAddedItem = {
      ...item,
      operator,
    };

    currentInput(itemsDataClone.children, path, newAddedItem);

    setItemsData(itemsDataClone);
  };

  const handleAddInput = (operator, itemData, path) => {
    setIsEdited(true);
    const fceItems = inputAdder(
      itemsData.children.length,
      operator,
      itemData.fce_item_id
    );
    const items = structuredClone(itemsData);

    addInput(path.length > 0 ? items.children : items, path, fceItems);

    setItemsData(items);
  };

  const changeXAttributePath = (eventTarget) => {
    setIsEdited(true);
    if (
      eventTarget.value?.charAt(0) === "$" &&
      eventTarget.value?.charAt(1) === "." &&
      eventTarget.value?.length > 2
    ) {
      eventTarget.classList.remove("is-invalid");
    } else {
      eventTarget.classList.add("is-invalid");
    }

    setXAttributePath(eventTarget.value);
  };

  return (
    <>
      <div className="row">
        {!hideFields ? (
          <>
            <div className="col-md col-12 mb-4">
              <label className="mb-0">{t("table_title")}</label>
              <input
                type="text"
                className="form-control border-radius-4"
                name="title"
                value={inputValues.title}
                onChange={(e) =>
                  setInputValues((prev) => ({ ...prev, title: e.target.value }))
                }
                disabled={revisionMode}
                required
              />
            </div>
            <div className="col-md col-12">
              <label className="mb-0">
                {t("identifier")}
                <span
                  className="badge badge-secondary ml-1"
                  data-tip={true}
                  data-for="name-hint"
                >
                  ?
                </span>
                <ReactTooltip
                  type="dark"
                  place="right"
                  effect="solid"
                  id="name-hint"
                >
                  {t("lowercase_numbers")}
                </ReactTooltip>
              </label>
              <input
                type="text"
                className="form-control border-radius-4"
                name="name"
                value={inputValues.name}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^a-zA-Z0-9_]/g, "");
                  setInputValues((prev) => ({ ...prev, name: newValue }));
                }}
                disabled={revisionMode}
                required
                pattern="^[a-zA-Z0-9_]+$"
                title={t("lowercase_numbers")}
              />
            </div>
          </>
        ) : (
          <>
            <input type="hidden" name="title" value={inputValues.title} />
            <input type="hidden" name="name" value={inputValues.name} />
            <input
              type="hidden"
              name="x_attribute_path"
              value={xAttributePath}
            />
          </>
        )}
      </div>
      <div className="row mb-2">
        {!hideFields && (
          <div className="col-md-6 col-12">
            <label className="mb-0">{t("result_attribute_path")}</label>
            <input
              type="text"
              className="form-control border-radius-4"
              name="x_attribute_path"
              value={xAttributePath}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value?.charAt(0) === "$" &&
                  value?.charAt(1) === "." &&
                  value?.length > 2
                ) {
                  e.target.classList.remove("is-invalid");
                } else {
                  e.target.classList.add("is-invalid");
                }
                setXAttributePath(value);
                setIsEdited(true);
              }}
              disabled={revisionMode}
            />
          </div>
        )}
      </div>
      <div className="mb-5 overflow-md-auto border-top-radius-left-right-3">
        <>
          <div className="table-title">{t("attributes")}</div>
          <div className="row m-0 attribute-header">
            <div className="col-6 right-border">{t("attribute_name")}</div>
            <div className="col right-border">{t("value_mapping")}</div>
            <div className="col column-delete right-border" />
          </div>
          {attributes.length
            ? attributes.map((attr, index) => {
                return (
                  <Attribute
                    addAttribute={addAttribute}
                    isLast={!attributes[index + 1]}
                    key={attr.fce_attribute_id}
                    attribute={attr}
                    fceAttrId={attr.fce_attribute_id}
                    vectorsData={vectorsDataClone}
                    attributes={attributes}
                    setAttributes={setAttributes}
                    handleDeleteAttributeModal={handleDeleteAttributeModal}
                  />
                );
              })
            : null}
        </>
      </div>
      {!revisionMode ? (
        <div className="align-items-center d-flex justify-content-between mb-1 ml-0">
          <div className="table-title mt-0">{t("function")}</div>
          <div className="d-flex align-items-center">
            <label className="mb-0 mr-2">{t("draggable_mode")}</label>
            <div className="custom-control custom-switch float-right align-middle">
              <input
                type="checkbox"
                className="custom-control-input w-100 z-index-100"
                onChange={() => setIsDraggable(!isDraggable)}
                checked={isDraggable}
              />
              <label className="custom-control-label" htmlFor="notifications" />
            </div>
          </div>
        </div>
      ) : null}
      <div className="attribute-container">
        <div className="mb-5 row flex-wrap">
          <div className="col d-flex function-icon pr-0 justify-content-end pt-2">
            <span>
              <FuncIcon /> =
            </span>
          </div>
          <div className="col-lg-11 col-12 overflow-md-auto">
            {isDraggable && !revisionMode ? (
              <DraggableFormula
                itemsData={itemsData}
                setIsDraggable={setIsDraggable}
                setItemsData={setItemsData}
                attributes={attributes}
              />
            ) : (
              <div className="flex-md-wrap overflow-auto input-group position-static function-content">
                <FormulaContext.Provider
                  value={{
                    changeItemsData,
                    handleAddInput,
                    isDraggable,
                    contextMenuItem,
                    setContextMenuItem,
                  }}
                >
                  <Brackets
                    item={itemsData}
                    setItem={setItemsData}
                    attributes={attributes}
                    revisionMode={revisionMode}
                  />
                </FormulaContext.Provider>

                {!revisionMode ? (
                  <>
                    <ContextMenu
                      preventHideOnContextMenu={false}
                      id={`context-menu-${contextMenuItem?.item?.fce_item_id}`}
                    >
                      {contextMenu.map((contextItem) => {
                        return (
                          <React.Fragment key={contextItem.id}>
                            {contextItem.divider && (
                              <div className="dropdown-divider border-0 m-0" />
                            )}
                            <MenuItem
                              onClick={() => {
                                if (!contextItem.children) {
                                  handleTypeChange(
                                    contextMenuItem?.item,
                                    contextItem.value,
                                    contextMenuItem?.path
                                  );
                                }
                              }}
                            >
                              {contextItem.children ? (
                                <div className="function-select-menu">
                                  <SubMenu
                                    title={contextItem.label}
                                    hoverDelay={100}
                                  >
                                    {contextItem.children?.map(
                                      (child, index) => {
                                        return (
                                          <MenuItem
                                            className="context-menu-item"
                                            key={index}
                                            onClick={() => {
                                              handleTypeChange(
                                                contextMenuItem?.item,
                                                child,
                                                contextMenuItem?.path,
                                                contextItem.value
                                              );
                                            }}
                                          >
                                            <Link
                                              className="context-menu-item-link"
                                              to="#"
                                              tabIndex="-1"
                                              value={child}
                                            >
                                              {child === "pi"
                                                ? "𝝅"
                                                : child === "rand"
                                                ? "random"
                                                : child}
                                            </Link>
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </SubMenu>
                                </div>
                              ) : (
                                <Link
                                  to="#"
                                  tabIndex="-1"
                                  value={contextItem.value}
                                  className={`${
                                    contextItem.label === "Delete"
                                      ? "delete-item-menu"
                                      : "context-menu-item-link"
                                  }`}
                                >
                                  {contextItem.label}
                                </Link>
                              )}
                            </MenuItem>
                          </React.Fragment>
                        );
                      })}
                    </ContextMenu>

                    <ContextMenu
                      preventHideOnContextMenu={false}
                      id={`operators-${contextMenuItem?.item?.fce_item_id}`}
                    >
                      {operators.map((operator) => {
                        return (
                          <React.Fragment key={operator.label}>
                            {operator.divider && (
                              <div className="dropdown-divider border-0 m-0" />
                            )}
                            <MenuItem
                              onClick={() =>
                                handleOperatorChange(
                                  contextMenuItem?.item,
                                  operator.label,
                                  contextMenuItem?.path
                                )
                              }
                            >
                              <Link
                                to="#"
                                tabIndex="-1"
                                value={operator.value}
                                className={`${
                                  operator.value === "delete"
                                    ? "delete-item-menu"
                                    : "context-menu-item-link"
                                }`}
                              >
                                {operator.label}
                              </Link>
                            </MenuItem>
                          </React.Fragment>
                        );
                      })}
                    </ContextMenu>
                  </>
                ) : null}
              </div>
            )}
          </div>
        </div>

        <Formula
          open={visualizeFormula}
          handleClose={() => setVisualizeFormula(false)}
          formula={
            <InlineMath math={`f(x) = ${formatFormula(visualizedFormula)}`} />
          }
        />
      </div>
      <DeleteConfirm
        handleClose={handleClose}
        handleConfirm={() => handleConfirm()}
        title={t("delete_attribute")}
        message={t("delete_attribute_message")}
        open={isDeleteAttributeModalOpen}
      />
    </>
  );
};

FunctionContent.propTypes = {
  info: PropTypes.object,
  items: PropTypes.any,
  attributes: PropTypes.array,
  setAttributes: PropTypes.func,
  visualizeFormula: PropTypes.bool,
  setVisualizeFormula: PropTypes.func,
  hideFields: PropTypes.bool, // New prop type
};

export default FunctionContent;
