import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MainContext } from "context/contexts";
import { EditorView } from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import {
  createDeployReleaseRequest,
  deleteReleaseRequest,
  getReleaseApiEndPointsRequest,
  getReleaseRequest,
  getTestReleaseRequest,
  updateReleaseNotesRequest,
  downloadBlueprintRequest,
  loadBatchTestResultsRequest,
} from "redux/releases/action";
import { startApprovalProcessRequest } from "redux/approvalProcesses/action";
import usePrevious from "utility/hooks/usePrevious";
import { getVectorAttributesRequest } from "redux/vectors/action";
import { toast } from "react-toastify";
import { ToastOptions } from "components/toastify";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as WindIcon } from "assets/icons/wind.svg";
import { ReactComponent as TruckIcon } from "assets/icons/truck.svg";
import { ReactComponent as ReleasesIcon } from "assets/icons/releases.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
import { ReactComponent as DeploymentIcon } from "assets/icons/deployments.svg";
import { ReactComponent as ExportIcon } from "assets/icons/export.svg";
import DeployRelease from "components/modals/release/deploy";
import ReleaseDetails from "components/modals/release/details";
import SubHeader from "components/SubHeader";
import DeleteConfirm from "../modals/DeleteConfirm";
import { useTranslation } from "react-i18next";
import ReleaseTreeTable from "../tree-table/ReleaseTreeTable";
import ApprovalProcessProgress from "./ApprovalProcessProgress";

import BatchTestUploader from "./batchTest/BatchTestUploader";
import BatchTestProgress from "./batchTest/BatchTestProgress";
import BatchTestResults from "./batchTest/BatchTestResults";
import ReactTooltip from "react-tooltip";
import "./Release.css";
import _, { set } from "lodash";

const Release = () => {
  const { vectorsData, setIsLoading, isMobile, isLoading } =
    useContext(MainContext);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDownloadBlueprint = () => {
    const filename = `${release.name}_${t("release_report")}.pdf`;
    dispatch(
      downloadBlueprintRequest({ id: release.workflow_release_id, filename })
    );
  };

  const settings = [
    {
      id: 1,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={deleteRelease}
          title={t("delete")}
        >
          <DeleteIcon /> {t("delete")}
        </button>
      ),
      divider: true,
    },
    {
      id: 2,
      content: (
        <button
          className="dropdown-item"
          type="button"
          onClick={handleDownloadBlueprint}
          title={t("download_report")}
        >
          <ExportIcon /> {t("download_report")}
        </button>
      ),
      divider: false,
    },
  ];

  const {
    isGetReleaseError,
    isGetTestReleaseSuccess,
    isGetTestReleaseError,
    release,
    testRelease,
    isGetReleaseApiEndPointsSuccess,
    isGetReleaseApiEndPointsError,
    releaseApiEndPoints,
    isCreatedDeployReleaseSuccess,
    isCreatedDeployReleaseRequest,
    isCreatedDeployReleaseError,
    isUpdatedReleaseNotesSuccess,
    isUpdatedReleaseNotesError,
    isDeletedReleaseSuccess,
    errorStatus,
    isDownloadBlueprintSuccess,
    isDownloadBlueprintError,
  } = useSelector((state) => state.releases);

  const {
    isGetVectorAttributesSuccess,
    isGetVectorAttributesError,
    vectorAttributes,
  } = useSelector((state) => state.vectors);

  const {
    isStartApprovalProcessSuccess,
    isStartApprovalProcessError,
    isStartApprovalProcessConflict,
    newApprovalProcessId,
  } = useSelector((state) => state.approvalProcesses);

  const prevIsGetReleaseError = usePrevious(isGetReleaseError);
  const prevIsGetTestReleaseError = usePrevious(isGetTestReleaseError);
  const prevIsGetVectorAttributesError = usePrevious(
    isGetVectorAttributesError
  );
  const prevIsGetReleaseApiEndPointsError = usePrevious(
    isGetReleaseApiEndPointsError
  );
  const prevIsUpdatedReleaseNotesError = usePrevious(
    isUpdatedReleaseNotesError
  );
  const prevIsUpdatedReleaseNotesSuccess = usePrevious(
    isUpdatedReleaseNotesSuccess
  );
  const prevIsCreatedDeployReleaseSuccess = usePrevious(
    isCreatedDeployReleaseSuccess
  );
  const prevIsCreatedDeployReleaseError = usePrevious(
    isCreatedDeployReleaseError
  );
  const prevIsGetReleaseApiEndPointsSuccess = usePrevious(
    isGetReleaseApiEndPointsSuccess
  );
  const prevIsGetTestReleaseSuccess = usePrevious(isGetTestReleaseSuccess);
  const prevIsGetVectorAttributesSuccess = usePrevious(
    isGetVectorAttributesSuccess
  );
  const prevIsDeletedReleaseSuccess = usePrevious(isDeletedReleaseSuccess);

  const prevIsStartApprovalProcessSuccess = usePrevious(
    isStartApprovalProcessSuccess
  );
  const prevIsStartApprovalProcessError = usePrevious(
    isStartApprovalProcessError
  );
  const prevIsStartApprovalProcessConflict = usePrevious(
    isStartApprovalProcessConflict
  );

  const prevIsDownloadBlueprintSuccess = usePrevious(
    isDownloadBlueprintSuccess
  );
  const prevIsDownloadBlueprintError = usePrevious(isDownloadBlueprintError);

  const [vectorId, setVectorId] = useState("");
  const [isCallDataSourcesChecked, setIsCallDataSourcesChecked] =
    useState(false);
  const [isRecordStepsChecked, setIsRecordStepsChecked] = useState(false);
  const [isRecordLineageChecked, setIsRecordLineageChecked] = useState(false);
  const [isReleaseDetailsModalOpen, setIsReleaseDetailsModalOpen] =
    useState(false);
  const [isDeployReleaseModalOpen, setIsDeployReleaseModalOpen] =
    useState(false);
  const [testingInputData, setTestingInputData] = useState({}); // State for API calls
  const testingDataRef = useRef({}); // Ref for tree table
  const [editorValue, setEditorValue] = useState(""); // Track raw editor value
  const [originalInputData, setOriginalInputData] = useState({}); // Track original loaded data
  const [testingOutputData, setTestingOutputData] = useState(null);
  const [isJsonValid, setIsJsonValid] = useState(true);
  const [releaseApiEndPointsClone, setReleaseApiEndPointsClone] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isInputJsonView, setIsInputJsonView] = useState(false);
  const [isOutputJsonView, setIsOutputJsonView] = useState(false);
  const [finishedSequences, setFinishedSequences] = useState([]);

  const [activeTab, setActiveTab] = useState("unit");

  // View mode constants
  const [outputViewMode, setOutputViewMode] = useState("tree"); // Manage view mode in a single state variable
  const [deltaOfTestingData, setDeltaOfTestingData] = useState({});

  useEffect(() => {
    document.title = t("release_capitalized") + " - Decisimo";
    setIsLoading(true);
    dispatch(getReleaseRequest(id));
    dispatch(getReleaseApiEndPointsRequest(id));
    dispatch(loadBatchTestResultsRequest({ releaseId: id }));
  }, []);

  useEffect(() => {
    if (vectorsData.length > 0) {
      setVectorId(vectorsData[0].vector_id);
    }
  }, [vectorsData]);

  useEffect(() => {
    if (
      (isGetReleaseError && prevIsGetReleaseError === false) ||
      (isGetTestReleaseError && prevIsGetTestReleaseError === false) ||
      (isGetReleaseApiEndPointsError &&
        prevIsGetReleaseApiEndPointsError === false) ||
      (isUpdatedReleaseNotesError &&
        prevIsUpdatedReleaseNotesError === false) ||
      (isGetVectorAttributesError && prevIsGetVectorAttributesError === false)
    ) {
      setIsLoading(false);
    }
  }, [
    isGetReleaseError,
    isGetTestReleaseError,
    isGetReleaseApiEndPointsError,
    isUpdatedReleaseNotesError,
    isGetVectorAttributesError,
  ]);

  useEffect(() => {
    if (
      isCreatedDeployReleaseSuccess &&
      prevIsCreatedDeployReleaseSuccess === false
    ) {
      setIsDeployReleaseModalOpen(false);
      setIsLoading(false);
      toast.info(t("deployed"), ToastOptions);
    }
  }, [isCreatedDeployReleaseSuccess]);

  useEffect(() => {
    if (
      isCreatedDeployReleaseError &&
      prevIsCreatedDeployReleaseError === false
    ) {
      setIsLoading(false);
      if (errorStatus === 406) {
        toast.error(t("deploy_warning"), ToastOptions);
      }
    }
  }, [isCreatedDeployReleaseError]);

  useEffect(() => {
    if (
      isUpdatedReleaseNotesSuccess &&
      prevIsUpdatedReleaseNotesSuccess === false
    ) {
      setIsLoading(false);
      toast.info(t("release_notes_updated"), ToastOptions);
    }
  }, [isUpdatedReleaseNotesSuccess]);

  useEffect(() => {
    if (
      isUpdatedReleaseNotesError &&
      prevIsUpdatedReleaseNotesError === false
    ) {
      setIsLoading(false);
      toast.info(t("release_notes_failed"), ToastOptions);
    }
  }, [isUpdatedReleaseNotesError]);

  useEffect(() => {
    if (isGetTestReleaseSuccess && prevIsGetTestReleaseSuccess === false) {
      setIsLoading(false);
      setTestingOutputData(testRelease);
      toast.success(t("release_tested"), ToastOptions);
    }
  }, [isGetTestReleaseSuccess, testRelease]);

  useEffect(() => {
    if (
      isGetReleaseApiEndPointsSuccess &&
      prevIsGetReleaseApiEndPointsSuccess === false
    ) {
      setIsLoading(false);
      setReleaseApiEndPointsClone(releaseApiEndPoints);
      if (release?.approval_process) {
        updateFinishedSequences(release.approval_process);
      }
    }
  }, [isGetReleaseApiEndPointsSuccess]);

  useEffect(() => {
    if (
      isGetVectorAttributesSuccess &&
      prevIsGetVectorAttributesSuccess === false
    ) {
      const newData = vectorAttributes || {};
      updateTestingData(newData);
      setIsLoading(false);
    }
  }, [isGetVectorAttributesSuccess, vectorAttributes]);

  const updateTestingData = (newData) => {
    const clonedData = structuredClone(newData);
    testingDataRef.current = clonedData;
    setTestingInputData(clonedData);
    setEditorValue(JSON.stringify(clonedData, null, 2));
  };

  useEffect(() => {
    if (isDeletedReleaseSuccess && prevIsDeletedReleaseSuccess === false) {
      setIsLoading(false);
      navigate("/releases");
      toast.warning(t("release_deleted"), ToastOptions);
    }
  }, [isDeletedReleaseSuccess]);

  //approval process
  const updateFinishedSequences = (approvalProcesses) => {
    const finished = [];
    approvalProcesses.forEach((process) => {
      const allStepsApproved = process.steps.every(
        (step) => step.status === "a"
      );
      if (allStepsApproved) {
        finished.push(process.approval_sequence_id);
      }
    });

    setFinishedSequences(finished);
  };

  //log delta between testingData and testingOutputData
  useEffect(() => {
    if (testingInputData && testingOutputData) {
      const delta = produceDeltaBetweenTesting(
        testingInputData,
        testingOutputData
      );
      setDeltaOfTestingData(delta);
    }
  }, [testingInputData, testingOutputData]);

  const produceDeltaBetweenTesting = (obj1, obj2) => {
    function changes(newObj, baseObj) {
      const combinedKeys = _.union(_.keys(newObj), _.keys(baseObj)); // Get all keys from both objects
      return _.transform(
        combinedKeys,
        (result, key) => {
          if (!_.has(baseObj, key)) {
            result[key] = newObj[key]; // Key only in newObj (added)
          } else if (_.isObject(newObj[key]) && _.isObject(baseObj[key])) {
            const deeperChanges = changes(newObj[key], baseObj[key]);
            if (!_.isEmpty(deeperChanges)) {
              result[key] = deeperChanges; // Recurse into nested objects
            }
          } else if (!_.isEqual(newObj[key], baseObj[key])) {
            result[key] = newObj[key]; // Changed values
          }
        },
        {}
      );
    }
    return changes(obj2, obj1);
  };

  const handleShowReleaseDetailsModal = () => {
    setIsReleaseDetailsModalOpen(true);
  };

  const handleClose = () => {
    setIsReleaseDetailsModalOpen(false);
  };

  const handleConfirm = (notes) => {
    const data = new URLSearchParams();
    data.append("release_notes", notes);
    setIsLoading(true);
    dispatch(updateReleaseNotesRequest({ id, data }));
  };

  const handleLoadObj = () => {
    if (vectorId) {
      setIsLoading(true);
      setTestingOutputData(null); // Clear previous output
      setDeltaOfTestingData({}); // Reset delta
      setEditorValue(""); // Reset editor value
      dispatch(getVectorAttributesRequest(vectorId));
    } else {
      toast.error(t("no_data_object_selected"), ToastOptions);
    }
  };

  const handleInputChange = (value) => {
    setEditorValue(value);
    try {
      const parsed = JSON.parse(value);
      updateTestingData(parsed);
      setIsJsonValid(true);
    } catch (e) {
      //clear othert toasts
      toast.dismiss();

      toast.error(t("invalid_json_input"), ToastOptions);
      setIsJsonValid(false);
    }
  };

  const handleTestRelease = () => {
    //check that we are not in JSON view and the data is valid json
    if (isInputJsonView && !isJsonValid) {
      toast.error(t("invalid_json_input"), ToastOptions);
      return;
    }

    const currentData = testingDataRef.current; // Use ref data directly
    setIsLoading(true);
    dispatch(
      getTestReleaseRequest({
        id,
        callExtData: isCallDataSourcesChecked ? "1" : "0",
        recordSteps: isRecordStepsChecked ? "1" : "0",
        testingInputData: currentData,
        recordLineage: isRecordLineageChecked ? "1" : "0",
      })
    );
  };

  const handleDeployReleaseClose = () => {
    setIsDeployReleaseModalOpen(false);
  };

  const handleDeployReleaseConfirm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("workflow_release_id", id);
    setIsLoading(true);
    dispatch(createDeployReleaseRequest(formData));
  };

  const handleCloseRelease = () => {
    setIsOpenModal(false);
  };

  const handleConfirmRelease = () => {
    dispatch(deleteReleaseRequest(id));
  };

  function deleteRelease() {
    setIsOpenModal(true);
  }

  const handleStartApprovalProcess = (approval_sequence_id) => {
    setIsLoading(true);
    dispatch(
      startApprovalProcessRequest({
        workflow_release_id: id,
        approval_sequence_id,
      })
    );
  };

  useEffect(() => {
    if (
      isStartApprovalProcessSuccess &&
      prevIsStartApprovalProcessSuccess === false
    ) {
      setIsLoading(false);
      toast.success(t("approval_process_started"), ToastOptions);
      dispatch(getReleaseRequest(id));
      setIsDeployReleaseModalOpen(false);
    }
  }, [isStartApprovalProcessSuccess]);

  useEffect(() => {
    if (
      isStartApprovalProcessError &&
      prevIsStartApprovalProcessError === false
    ) {
      setIsLoading(false);
      toast.error(t("failed_to_start_approval_process"), ToastOptions);
    }
  }, [isStartApprovalProcessError]);

  useEffect(() => {
    if (
      isStartApprovalProcessConflict &&
      prevIsStartApprovalProcessConflict === false
    ) {
      setIsLoading(false);
      toast.error(t("approval_process_already_started"), ToastOptions);
    }
  }, [isStartApprovalProcessConflict]); // Add an effect to hide the loading spinner when download completes
  useEffect(() => {
    if (
      (isDownloadBlueprintSuccess &&
        prevIsDownloadBlueprintSuccess === false) ||
      (isDownloadBlueprintError && prevIsDownloadBlueprintError === false)
    ) {
      setIsLoading(false);
      if (isDownloadBlueprintError) {
        toast.error(t("report_generation_failed"), ToastOptions);
      }
    }
  }, [isDownloadBlueprintSuccess, isDownloadBlueprintError]);

  return (
    <>
      <SubHeader
        alt={t("release_capitalized")}
        title={`${t("release_capitalized")}: ${release?.name || ""}`}
        release={true}
        icon={<ReleasesIcon />}
        actions={
          <>
            <button
              className="btn outline mr-2"
              onClick={() => setIsDeployReleaseModalOpen(true)}
              title={t("deploy_release")}
              data-tip
              data-for="deploy-release-tooltip"
            >
              <DeploymentIcon />
            </button>
            <ReactTooltip
              id="deploy-release-tooltip"
              effect="solid"
              place="left"
            >
              {t("deploy_release")}
            </ReactTooltip>

            <button
              className="btn outline mr-1"
              type="button"
              onClick={handleShowReleaseDetailsModal}
              title={t("release_details")}
            >
              <InfoIcon />
            </button>
            <button
              type="button"
              className="btn primary"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-expanded="false"
              title={t("further_actions")}
            >
              <SettingsIcon />
            </button>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-position">
              {settings.length > 0 &&
                settings.map((setting) => {
                  return (
                    <span key={setting.id}>
                      {setting.content}
                      {setting.divider && <div className="dropdown-divider" />}
                    </span>
                  );
                })}
            </div>
          </>
        }
      />
      <DeleteConfirm
        handleClose={handleCloseRelease}
        handleConfirm={handleConfirmRelease}
        title={t("delete_release")}
        message={
          <span>
            {t("do_you_want_to_delete")} <b>{release.name}</b> {t("release")}?
          </span>
        }
        open={isOpenModal}
      />
      <div className="mt-4">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "unit" ? "active" : ""}`}
              onClick={() => setActiveTab("unit")}
            >
              {t("unit_test")}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "batch" ? "active" : ""}`}
              onClick={() => setActiveTab("batch")}
            >
              {t("batch_test")}
            </button>
          </li>
        </ul>
      </div>
      <div className="tab-content mt-3">
        {/* Unit Test Tab */}
        {activeTab === "unit" && (
          <>
            <div className="tab-pane active">
              <div className="row">
                <div className="col-md-6 col-12 mb-md-0 mb-4">
                  <label>{t("testing_input")}</label>
                  <div className="input-group">
                    <select
                      onChange={(e) => setVectorId(e.target.value)}
                      value={vectorId}
                      className="form-control"
                    >
                      {vectorsData.map((vector) => {
                        if (vector.vector_id > 0) {
                          return (
                            <option
                              key={vector.vector_id}
                              value={vector.vector_id}
                            >
                              {vector.title}
                            </option>
                          );
                        }
                      })}
                    </select>
                    <div className="input-group-append">
                      <button
                        className="input-group-text m-0"
                        onClick={handleLoadObj}
                      >
                        {t("load_object")}
                      </button>
                    </div>
                  </div>
                  <div
                    style={{
                      height: 590,
                      display: !testingInputData ? "flex" : undefined,
                    }}
                  >
                    {(!testingInputData ||
                      Object.keys(testingInputData || {}).length === 0) &&
                    !isInputJsonView ? (
                      <div style={{ margin: "auto" }}>
                        {t("please_select_and_load_an_object_to_begin")}
                      </div>
                    ) : isInputJsonView ? (
                      <CodeMirror
                        value={JSON.stringify(testingDataRef.current, null, 2)}
                        extensions={[json(), EditorView.lineWrapping]}
                        height="590px"
                        onChange={handleInputChange}
                        className={!isJsonValid ? "invalid-json" : ""}
                        basicSetup={{
                          lineNumbers: true,
                          highlightActiveLine: true,
                        }}
                      />
                    ) : (
                      testingInputData && (
                        <ReleaseTreeTable testingData={testingDataRef} />
                      )
                    )}
                  </div>
                  <div
                    className="convert-json btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <label className="btn outline border-radius-left-4 active">
                      <input
                        type="radio"
                        value="0"
                        checked={!isInputJsonView}
                        onChange={() => setIsInputJsonView(false)}
                      />
                      {t("tree")}
                    </label>
                    <label className="btn outline border-radius-right-4">
                      <input
                        type="radio"
                        value="1"
                        checked={isInputJsonView}
                        onChange={() => setIsInputJsonView(true)}
                      />
                      JSON
                    </label>
                  </div>
                </div>
                <div className="col-md-6 col-12 mb-md-0 mb-4">
                  <label>{t("output")}</label>
                  <div
                    style={{
                      height: "628px",
                      display: !testingOutputData ? "flex" : undefined,
                    }}
                  >
                    {testingOutputData ? (
                      outputViewMode === "deltaJson" ? (
                        <CodeMirror
                          key={`delta-${JSON.stringify(deltaOfTestingData)}`}
                          value={JSON.stringify(deltaOfTestingData, null, 2)}
                          extensions={[json(), EditorView.lineWrapping]}
                          cursor={{ line: 1, ch: 1 }}
                          height="628px"
                          readOnly="nocursor"
                        />
                      ) : outputViewMode === "json" ? (
                        <CodeMirror
                          key={`output-${JSON.stringify(testingOutputData)}`}
                          value={JSON.stringify(testingOutputData, null, 2)}
                          extensions={[json(), EditorView.lineWrapping]}
                          cursor={{ line: 1, ch: 1 }}
                          height="628px"
                          readOnly="nocursor"
                        />
                      ) : outputViewMode === "deltaTree" ? (
                        <ReleaseTreeTable
                          testingData={{ current: deltaOfTestingData }}
                          type="delta"
                        />
                      ) : (
                        <ReleaseTreeTable
                          testingData={{ current: testingOutputData }}
                          type="output"
                        />
                      )
                    ) : (
                      <div style={{ margin: "auto" }}>
                        {t("please_run_a_test_to_see_output")}
                      </div>
                    )}
                  </div>
                  <div
                    className="convert-json btn-group-toggle"
                    data-toggle="buttons"
                  >
                    <label className="btn outline border-radius-left-4 active">
                      <input
                        type="radio"
                        value="0"
                        checked={outputViewMode === "tree"}
                        onChange={() => setOutputViewMode("tree")}
                      />
                      {t("tree")}
                    </label>
                    <label className="btn outline">
                      <input
                        type="radio"
                        value="1"
                        checked={outputViewMode === "deltaTree"}
                        onChange={() => setOutputViewMode("deltaTree")}
                      />
                      Δ Tree
                    </label>
                    <label className="btn outline">
                      <input
                        type="radio"
                        value="1"
                        checked={outputViewMode === "json"}
                        onChange={() => setOutputViewMode("json")}
                      />
                      JSON
                    </label>

                    <label className="btn outline border-radius-right-4">
                      <input
                        type="radio"
                        value="1"
                        checked={outputViewMode === "deltaJson"}
                        onChange={() => setOutputViewMode("deltaJson")}
                      />
                      Δ JSON
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-5 row">
                <div className="col text-right">
                  <button
                    disabled={isLoading}
                    className="btn outline"
                    onClick={handleTestRelease}
                    data-tip
                    data-for="test-release-tooltip"
                    onDoubleClick={() => {
                      toast.error(t("let_test_finish"), ToastOptions);
                    }}
                  >
                    <WindIcon />{" "}
                    <span className="ml-2">{t("test_release")}</span>
                  </button>
                  {((!isJsonValid && isInputJsonView) || isLoading) && (
                    <ReactTooltip
                      id="test-release-tooltip"
                      effect="solid"
                      place="top"
                    >
                      {t("cannot_run_test")}{" "}
                      {isLoading
                        ? t("let_test_finish")
                        : !isJsonValid && isInputJsonView
                        ? t("invalid_json_input")
                        : ""}
                    </ReactTooltip>
                  )}
                </div>
                <div className="col text-left"></div>
              </div>
              <div className="row mb-5 mt-4">
                <div className="col">
                  <div className="row mt-1">
                    <div className="col-4">
                      <label className="align-middle">
                        {t("call_data_sources")}?{" "}
                      </label>
                    </div>
                    <div className="col">
                      <div
                        className="btn-group btn-group-toggle ml-2"
                        data-toggle="buttons"
                      >
                        <label className="btn outline border-radius-left-4 active">
                          <input
                            type="radio"
                            name="call_external_data"
                            value="0"
                            checked="checked"
                            onChange={(e) =>
                              setIsCallDataSourcesChecked(!e.target.checked)
                            }
                          />
                          {t("no")}
                        </label>
                        <label className="btn outline border-radius-right-4">
                          <input
                            type="radio"
                            name="call_external_data"
                            value="1"
                            onChange={(e) =>
                              setIsCallDataSourcesChecked(e.target.checked)
                            }
                          />
                          {t("yes")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-4">
                      <label className="align-middle">
                        {t("record_executed_steps")}?{" "}
                      </label>
                    </div>
                    <div className="col">
                      <div
                        className="btn-group btn-group-toggle ml-2"
                        data-toggle="buttons"
                      >
                        <label className="btn outline border-radius-left-4 active">
                          <input
                            type="radio"
                            name="record_steps"
                            value="0"
                            checked="checked"
                            onChange={(e) =>
                              setIsRecordStepsChecked(!e.target.checked)
                            }
                          />
                          {t("no")}
                        </label>
                        <label className="btn outline border-radius-right-4 ">
                          <input
                            type="radio"
                            name="record_steps"
                            value="1"
                            onChange={(e) =>
                              setIsRecordStepsChecked(e.target.checked)
                            }
                          />
                          {t("yes")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-1">
                    <div className="col-4">
                      <label className="align-middle">
                        {t("record_lineage")}?{" "}
                      </label>
                    </div>
                    <div className="col">
                      <div
                        className="btn-group btn-group-toggle ml-2"
                        data-toggle="buttons"
                      >
                        <label
                          className={`btn outline border-radius-left-4 ${
                            !isRecordLineageChecked ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="record_lineage"
                            value="0"
                            checked={!isRecordLineageChecked}
                            onChange={() => setIsRecordLineageChecked(false)}
                          />
                          {t("no")}
                        </label>
                        <label
                          className={`btn outline border-radius-right-4 ${
                            isRecordLineageChecked ? "active" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            name="record_lineage"
                            value="1"
                            checked={isRecordLineageChecked}
                            onChange={() => setIsRecordLineageChecked(true)}
                          />
                          {t("yes")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Batch Test Tab */}
        {activeTab === "batch" && (
          <div className="tab-pane active">
            <div className="row">
              <div className="col">
                {/* Batch Testing Components */}
                <BatchTestUploader releaseId={id} />
                <BatchTestProgress />
                <BatchTestResults
                  releaseId={id}
                  decisionFlowTitle={release.workflow_title}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <DeployRelease
        open={isDeployReleaseModalOpen}
        isRequestLoading={isCreatedDeployReleaseRequest}
        handleClose={handleDeployReleaseClose}
        handleConfirm={handleDeployReleaseConfirm}
        data={releaseApiEndPointsClone}
        finishedSequences={finishedSequences}
        startApprovalProcess={handleStartApprovalProcess}
      />
      <ReleaseDetails
        open={isReleaseDetailsModalOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
        data={release}
      />

      {release?.approval_process &&
        Array.isArray(release.approval_process) &&
        release.approval_process.length > 0 && (
          <ApprovalProcessProgress
            approval_process={release.approval_process}
            startApprovalProcess={handleStartApprovalProcess}
          />
        )}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loader"></div>
        </div>
      )}
    </>
  );
};

export default Release;
